import Axios, { AxiosResponse } from "axios";
import queryString from 'query-string';
import env from "../config/env";
import SessionService from "./SessionService";

interface IHeaders {
  headers: { Authorization: string }
}

class ApiService {

  public get(path: string, params = {}): Promise<AxiosResponse> {
    return Axios.get(this.getBaseUrl() + path + this.createUrlParam(params), this.buildConfig())
  }

  public post(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.post(this.getBaseUrl() + path, data, this.buildConfig(option))
  }

  public put(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.put(this.getBaseUrl() + path, data, this.buildConfig(option));
  }

  public delete(path: string): Promise<AxiosResponse> {
    return Axios.delete(this.getBaseUrl() + path, this.buildConfig());
  }

  public buildConfig(options?: any) {
    let headers: IHeaders = this.buildHeader(options?.multipart);
    return Object.assign(headers, options);
  }

  private buildHeader(multipart = false): IHeaders {
    let token: string = SessionService.getToken();
    let header = { headers: { Authorization: `Bearer ${token}` } };
    if (multipart) header.headers['content-type'] = 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL';
    return header;
  }

  public getBaseUrl(): string {
    return env.protocol + env.apiUrl + "api/";
  }

  private createUrlParam(params: any) {
    if (Object.keys(params).length === 0) return ""
    if (params["where"]) params["where"] = params["where"].map(w => w.toString());
    return "?" + queryString.stringify(params, {arrayFormat: 'index'});
  }
}

export default new ApiService()
