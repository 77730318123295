import { AxiosResponse } from "axios";
import humps from 'lodash-humps';
import Model from '../models/Model'
import apiService from "../services/ApiService";
import ModelResponse from './apiResponse/ModelResponse';
import ModelCollectionResponse from './apiResponse/ModelCollectionResponse';
import PaginateResponse from './apiResponse/PaginateResponse';
import DOMService from './DOMService';

export default class EntityManager {

  static async all<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
    let params = EntityManager.buildParam(options.params, options);
    let response: AxiosResponse = await apiService.get(fullpath, params);
    return new ModelCollectionResponse<T>(response, modelClass);
  }

  static async allWithPaginate<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
    let params = EntityManager.buildParam(options.params, options);
    let response: AxiosResponse = await apiService.get(fullpath, {...params, paginate: true});
    return new PaginateResponse<T>(response, modelClass);
  }

  static async show<T extends Model>(modelClass: any, id, options: EntityManagerOption = {}) {
    let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
    let params = EntityManager.buildParam(options.params, options);
    let response: AxiosResponse = await apiService.get(`${fullpath}/${id}`, params);
    return new ModelResponse<T>(response, modelClass);
  }

  static async create<T extends Model>(model: T, options: CreateOptions = {}) {
    let fullpath = EntityManager.getPath(model.constructor, options.parentModel);
    let param = EntityManager.buildParam(model.getApiParam(options.only), options);
    let response: AxiosResponse = await apiService.post(fullpath, param, {multipart: options.multipart});
    return new ModelResponse<T>(response, model.constructor);
  }

  static async update<T extends Model>(model: T, options: UpdateOptions = {}) {
    let param = EntityManager.buildParam(model.getApiParam(options.only), options);
    let path;
    if (param instanceof FormData) {
      param.append('_method', 'PUT');
      path = param.get('id')
    } else {
      path = param.id;
      param['_method'] = 'PUT'
    }
    let fullpath = EntityManager.getPath(model.constructor, options.parentModel, path);
    let promise = apiService.post(fullpath, param, {multipart: options.multipart})
    if (options.toaster) DOMService.dialog(model, promise)
    let response: AxiosResponse = await promise;
    return new ModelResponse<T>(response, model.constructor);
  }

  static async delete<T extends Model>(model: T, options: DeleteOptions = {}): Promise<any> {
    let fullpath = EntityManager.getPath(model.constructor, options.parentModel, model.id);
    let response: AxiosResponse = await apiService.delete(fullpath)
    return new ModelResponse<T>(response, model.constructor);
  }

  static createMany<T extends Model>(modelClass, data: any[]): Promise<any> {
    return EntityManager.create<T>(modelClass, {params: {models: data}});
  }

  static async updateMany<T extends Model>(modelClass, options: UpdateOptions = {}): Promise<any> {
    let param = {models: options.params};
    
    param.models = param.models.map((m) => m.getApiParam(options.only))
    param = EntityManager.buildParam(param, options);
    if (param instanceof FormData) {
      param.append('_method', 'PUT');
    } else {
      param['_method'] = 'PUT'
    }
    let fullpath = EntityManager.getPath(modelClass, options.parentModel, "all");
    let response: AxiosResponse = await apiService.post(fullpath, param, {multipart: options.multipart})
    return new ModelResponse<T>(response, modelClass);
  }

  static async get<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
      response = await apiService.get(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelResponse<T>(response, modelClass);
  }

  static async getCollection<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
      response = await apiService.get(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelCollectionResponse<T>(response, modelClass);
  }

  static async post<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
      response = await apiService.post(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelResponse<T>(response, modelClass);
  }

  static async postCollection<T extends Model>(modelClass: any, options: EntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = EntityManager.getPath(modelClass, options.parentModel, options.path);
      response = await apiService.post(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelCollectionResponse<T>(response, modelClass);
  }

  static buildParam(params, options: EntityManagerOption) {
    if (options.merge) params = Object.assign(params, options.merge);
    if (options.with) params = Object.assign(params, {_with: options.with});
    return params;
  }

  static toCamelCase(json) {
    return humps(json);
  }

  static toModel(modelClass, json) {
    return new modelClass(EntityManager.toCamelCase(json));
  }

  static toModels(modelClass, array: any[]) {
    return array.map(json => new modelClass(EntityManager.toCamelCase(json)));
  }

  static getPath(modelClass: any, parentModel?: any, path?: string): string {
    let fullpath = modelClass.modelUrl
    if (parentModel) {
      const parentUrl = parentModel.constructor?.modelUrl;
      fullpath = `${parentUrl}/${parentModel.id}/${fullpath}`;
    }
    if (path) {
      fullpath += `/${path}`
    }
    return fullpath;
  }

  static displayError(response: AxiosResponse) {
  }

}

export interface EntityManagerOption {
  parentModel?: any,
  params?: any
  direct?: boolean
  path?: string
  toaster?: boolean
  with?: string[]
  only?: string[]
  merge?: Object
  multipart?: boolean
}

export interface CreateOptions extends EntityManagerOption {
}

export interface UpdateOptions extends EntityManagerOption {
}

export interface DeleteOptions extends EntityManagerOption {
  confirmMessage?: string
}
