import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Gift from '../../models/Gift';
import Lottery from '../../models/Lottery';
import EntityManager from '../../services/EntityManager';
import SmartInputNumber from '../input/input/SmartInputNumber';
import SmartInputText from '../input/input/SmartInputText';
import recordManager from '../logic/RecordManager';
import GiftModelSelect from '../modelSelect/GiftModelSelect';

export interface IAdminLotteryProps {
  id_boutique: any
}

export interface IAdminLotteryState {
  warningMessage: boolean
}

class AdminLottery extends React.Component<any, IAdminLotteryState> {

  public gifts: Gift[]

  constructor(props: any) {
    super(props);

    this.state = {
      warningMessage: false
    }
  }
  async componentDidMount() {
    this.gifts = (await EntityManager.all<Gift>(Gift)).models;
    this.setState({});
  }

  async saveLotteries() {
    await EntityManager.updateMany(Lottery, {params: this.props.models});
  }

  sumProbability() {
    return this.props.models.map(l => l.probability).reduce((add, value) => add + value, 0);
  }

  checkSum() {
    if (this.sumProbability() !== 100) this.setState({warningMessage: true});
    else this.setState({warningMessage: false})
  }

  public render() {
    const {models} = this.props
    if (!this.gifts) return <></>
    return (
      <div className="p-3">
        <div className="row-flex mb-3">
          <NavLink to={"/" + this.props.id_boutique +"/rewards"} exact className="btn btn-link">
            <i className="fas fa-chevron-left"></i>
          </NavLink>
          <div className="form-title">
            Gains de la lotterie
          </div>
          <button onClick={() => this.saveLotteries()} className="btn btn-primary ml-auto">Enregistrer</button>
        </div>
        {this.state.warningMessage && <div className="alert alert-danger">Attention la somme des probabilité n'est pas égale à <span>100.</span></div>}
        <div className="row-flex">
          <div style={{flex: "0 0 30px"}}></div>
          <div className="flex-1 text-secondary">Récompense</div>
          <div className="flex-1 text-secondary">Message</div>
          <div className="flex-1 text-secondary">Probabilité</div>
        </div>
        {models && models.map((lottery, index) => (
          <div key={lottery.id} className="row-flex py-2">
            <div className="font-weight-bold" style={{flex: "0 0 30px"}}>{index + 1}</div>
            <GiftModelSelect className="mr-3" model={lottery} name="giftId" models={this.gifts} />
            <SmartInputText className="mr-3" model={lottery} name="message" />
            <SmartInputNumber onChange={() => this.checkSum()} className="mr-3" model={lottery} name="probability" rightIcon={<i className="fas fa-percent text-secondary"></i>} />
          </div>
        ))}
      </div>
    );
  }
}

export default recordManager(AdminLottery, {modelClass: Lottery, loadOnReady: true})
