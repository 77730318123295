import * as React from 'react';
import Gift from '../../models/Gift';
import { NavLink } from 'react-router-dom';
import FragmentLink from '../logic/FragmentLink';
import recordManager from '../logic/RecordManager';
import ImageBg from '../common/ImageBg';
import classNames from 'classnames';

export interface IGiftListProps {
  id_boutique: any
}

export interface IGiftListState {
}

class GiftList extends React.Component<any, IGiftListState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {models} = this.props;
    return (
      <div className="p-3">
        <div className="action-bar row-flex mb-3">
          <NavLink to={"/" + this.props.id_boutique +"/rewards"} exact className="btn btn-link">
            <i className="fas fa-chevron-left"></i>
          </NavLink>
          <h4 className="mb-0 font-weight-bold">Tous les cadeaux</h4>
          <NavLink to={"/" + this.props.id_boutique +"/rewards/new"} className="btn btn-primary ml-auto">Nouveau</NavLink>
        </div>
        <div className="actualities-container">
          {
            models && models.map((gift: Gift) => (
            <FragmentLink key={gift.id} to={`/${this.props.id_boutique}/rewards/gifts/${gift.id}/edit`} >
              <div className={classNames({"card p-2": true})}>
                <div className="row-flex">
                  <ImageBg size="70px" url={gift.image?.url}/>
                  <div className="ml-3">
                    <div className={classNames({"font-weight-bold": true, "text-warning": gift.isBigDeal()})}>{gift.title}</div>
                    {gift.isPercent() && <div>Réduction de {gift.percent} %</div>}
                    {gift.isAmount() && <div>Réduction de {gift.amount} €</div>}
                    {gift.isProductFree() && <div>Produit offert</div>}
                  </div>
                </div>
              </div>
            </FragmentLink>
          ))}
        </div>
      </div>
    );
  }
}

export default recordManager(GiftList, {modelClass: Gift, loadOnReady: true})