import classNames from 'classnames';
import { uniqueId } from 'lodash';
import * as React from 'react';
import SmartInput, { ISmartInputProps } from './SmartInput';


export interface ISmartSwitchProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

export default function SmartSwitch(props: ISmartSwitchProps) {

  const id = uniqueId();

  const { className, color, primary } = props;
  return (
    <SmartInput
      {...props}
      render={({leftIcon, containerClassName, updateState, label, inputProps}) => (
        <div className={ classNames({ [className]: !!className, "smart-switch": true, "smart-switch-primary": primary })}>
          <input {...inputProps} checked={inputProps.value} type="checkbox" onChange={ (e) => updateState(e.currentTarget.checked, e) }/>
          <label style={{background: inputProps.checked ? color : null}} htmlFor={id} className="check-trail">
            <span className="check-handler"></span>
          </label>
          <label htmlFor={id} className="switch-label mb-0">
            {label}
          </label>
        </div>
      )}
    />
  );
}
