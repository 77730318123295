import { capitalize } from "lodash";
import Model from './Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class Address extends Model {

  public address: string;
  public addressComp: string;
  public cp: string;
  public city: string;
  public latitude: number;
  public longitude: number;
  public selected: boolean

  static modelUrl = "addresses";
  public modelName = "address";

  public attributes = new AttributesCollection({
    type: new Attribute("type"),
    address: new Attribute("address"),
    addressComp: new Attribute("addressComp"),
    cp: new Attribute("cp"),
    city: new Attribute("city"),
    latitude: new Attribute("latitude"),
    longitude: new Attribute("longitude"),
    selected: new Attribute("selected"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  isEmpty(): boolean {
    return !this.address && !this.addressComp && !this.cp && !this.city;
  }

  shortAddress(): string {
    let properties = [this.address, this.city].filter((p) => p);
    return properties.map((p) => capitalize(p)).join(", ");
  }

  cpAndCity() {
    let properties = [this.cp, this.city].filter((p) => p);
    return properties.map((p) => capitalize(p)).join(" ");
  }

  fullAddress(): string {
    let properties = [this.address, this.addressComp, this.cp, this.city].filter((p) => p);
    return properties.join(" ");
  }
}