import * as React from 'react';
import OrderComposition from '../../../models/OrderComposition';
import ImageBg from '../../common/ImageBg';
import TextIcon from '../../common/TextIcon';
import classNames from 'classnames';

export interface IOrderCompositionItemProps {
  compo: OrderComposition
}

export interface IOrderCompositionItemState {
}

export default class OrderCompositionItem extends React.Component<IOrderCompositionItemProps, IOrderCompositionItemState> {
  constructor(props: IOrderCompositionItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { compo } = this.props;
    return (
      <div>
        <div className="d-flex mb-1">
          <div className="pr-3">
            <ImageBg size="60px" url={compo.product.baseProduct.image?.url} />
          </div>
          <div className="w-100">
            <div className="row-between mb-1">
              <div>{compo.product.baseProduct.name}</div>
              <div className="ml-3">X{compo.quantity}</div>
            </div>
            <div className="row-between">
              <div>
                { compo.product.baseProduct.isPizza() && <div>{compo.product.size.label}</div>}
              </div>
              <div>{compo.sellPrice} €</div>
            </div>
          </div>
        </div>
        { compo.extras.length > 0 && <div className="px-2 pb-3">
          <div className="text-secondary font-weight-bold">{compo.product.baseProduct.isPmP() ? "Composition" : "Extras"}</div>
          {compo.product.baseProduct.isPmP() && (
            <TextIcon leftIcon={classNames({"fas fa-circle": true, [compo.product.baseProduct.productCategory.key === "pizza_base_tomate" ? "text-danger" : "text-warning"]: true})}>{compo.product.baseProduct.productCategory.label}</TextIcon>
          )}
          {compo.extras.map(extra => (
            <div className="row-flex">
              <TextIcon leftIcon="fas fa-circle">
                {extra.product.baseProduct.name}
              </TextIcon>
              <div className="ml-3">
                <div>X{extra.quantity}</div>
              </div>
            </div>
          ))}
        </div>}
      </div>
    );
  }
}
