import * as React from 'react';
import { withRouter } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import FormModel from '../../logic/FormModel';
import { RouteComponentProps } from 'react-router';

export interface IProductFormLogicProps {
  render: (p: BaseProduct) => void
  id?: string
}

export interface IProductFormLogicState {
}

class ProductFormLogic extends React.Component<RouteComponentProps & IProductFormLogicProps & any, IProductFormLogicState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  submit = async () => {
    let submitted = await this.props.submit(this.props.model);
    if (submitted) this.toList();
  }

  toList = () => {
    this.props.history.goBack()
  }

  renderDeleteFooter = () => {
    const {model, manager} = this.props;
    if (model.id) return (
      <div className="text-center mb-5">
      <hr/>
      <div onClick={() => manager.delete(model, this.toList)} className="text-danger mt-2">Supprimer</div>
    </div>
    )
    return <></>
  }

  public render() {
    const {model} = this.props;
    if (!model) return <></>
    return this.props.render(model, () => this.submit(), this.renderDeleteFooter);
  }
}

export default withRouter(FormModel(ProductFormLogic, {modelClass: BaseProduct}))