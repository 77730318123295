import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import { OptionAttribute } from './core/OptionAttribute';
import Model from "./Model";
import Option from './Option';
import Product from './Product';
import ImageModel from './ImageModel';
import ModelAttribute from './core/ModelAttribute';
import RequiredValidator from './core/form/validators/RequiredValidator';
import OptionService from '../services/OptionService';
import BaseProductInfo from './BaseProductInfo';

export default class BaseProduct extends Model {

  public name: string
  public description: string
  public mainImageUrl: string;

  public productCategory: Option;
  public status: Option;
  public available: boolean;
  public tva: Option;
  public statusId: number;
  public productCategoryId: number
  public tvaId: number;
  public images: ImageModel[];
  public image: ImageModel;

  public products: Product[]

  public baseProductInfos: BaseProductInfo

  public requiredField = ["name"]
  static modelUrl = "base_products";
  public modelName = "baseProduct";
  
  public attributes = new AttributesCollection({
    name: new Attribute("name", {validator: RequiredValidator}),
    description: new Attribute("description"),
    available: new Attribute("available"),
    productCategory: new OptionAttribute("productCategory", {optionType: "product_category"}),
    status: new OptionAttribute("status", {optionType: "product_status"}),
    tva: new OptionAttribute("tva"),
    image: new ModelAttribute("image", ImageModel, {submittable: false}),
    products: new ArrayModelAttribute("products", Product),
    images: new ArrayModelAttribute("images", ImageModel),
    baseProductInfos: new ModelAttribute("baseProductInfos", BaseProductInfo)
  });

  constructor(json) {
    super(json)
    json.baseProductInfos = json.baseProductInfos ? json.baseProductInfos[0]: null;
    this.fillAttributes(json);
  }

  getCategory(): Option {
    let category: Option = this.productCategory;
    if (!category.optionId) return category;
    return OptionService.find(category.optionId);
  }

  isPizza() {
    return this.getCategory().key === "pizza";
  }

  isPmP() {
    return this.name === "pimpmypizz";
  }
}












