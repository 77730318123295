import { AxiosResponse } from "axios";
import LoginResponse from './apiResponse/LoginResponse';
import ApiService from "./ApiService";
import SessionService from "./SessionService";
import UserManager from "./UserManager";
import EntityManager from './EntityManager';
import User from '../models/User';

export interface Credential {
  email?: string,
  password?: string
  googleId?: string
  facebookId?: string
}

export class AuthService {

  public usersManager: UserManager = new UserManager();

  public async login(credential: Credential): Promise<LoginResponse> {
    let loginResponse = await this.usersManager.login(credential);
    if (loginResponse.ok) {
      SessionService.setUser(loginResponse.user);
      // SessionService.storeUser(loginResponse.user);
      SessionService.storeToken(loginResponse.token);
    }
    return loginResponse;
  }

  loginWithGoogle(response) {
    return this.login({googleId: response.profileObj.googleId});
  }

  loginWithFacebook(response) {
    return this.login({facebookId: response.id});
  }

  public async me() {
    let response = await this.usersManager.me();
    SessionService.setUser(response.model);
    if (!response.ok) SessionService.deleteToken();
  }

  public async validateEmail(email: string): Promise<boolean> {
    let response: AxiosResponse = await ApiService.post("validate", {email});
    return response.data.exist;
  }

  public async signin(credential: any) {
    return await this.usersManager.signin(credential);
  }

  public logout() {
    EntityManager.post(User, {path: "logout", params: {deviceToken: SessionService.deviceToken}})
    SessionService.deleteToken();
  }

}

const authService = new AuthService();
export default authService;
