import * as React from 'react';
import dayjs from 'dayjs';

export interface ITimerProps {
  referenceDate: Date
}

export interface ITimerState {
}

export default class Timer extends React.Component<ITimerProps, ITimerState> {

  interval;

  componentDidMount() {
    this.interval = setInterval(() => this.setState({}), 1000);
  }

  isLate() {
    return dayjs(this.props.referenceDate).isBefore(dayjs())
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getValue() {
    if(this.isLate()){
      
      let totalSeconds = dayjs().diff(dayjs(this.props.referenceDate), "second")
      let value = new Date(totalSeconds * 1000).toISOString().substr(11, 8);
      return value
    }else{
      let totalSeconds = dayjs(this.props.referenceDate).diff(dayjs(), "second");
      let value = new Date(totalSeconds * 1000).toISOString().substr(11, 8);
      
      if (value.startsWith("00")) return value.substr(3);
      return value
    }
    
  }

  public render() {
    return (
      <div>
        {this.isLate() ?
          <div className="text-center font-weight-bold text-info">
            <div>RETARD !</div>
            <div>Bon cadeau</div>
            <div className="timer">-{this.getValue()}</div>
          </div> :
          <div className="timer">{this.getValue()}</div>
        }
        
      </div>
    );
  }
}
