import * as React from 'react';
import { NavLink } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import Product from '../../../models/Product';
import ImageGallery from '../../common/ImageGallery';
import SmartInputNumber from '../../input/input/SmartInputNumber';
import SmartInputText from '../../input/input/SmartInputText';
import SmartOptionSelect from '../../input/input/SmartOptionSelect';
import ProductFormLogic from './ProductFormLogic';

export default class IngredientSaladeForm extends React.Component<any> {


  public render() {
    let newModel = new BaseProduct({products: [new Product({})]});
    newModel.setOptionAttributesToDefault();
    return (
      <ProductFormLogic
        id={this.props.id}
        newModel={newModel}
        render={(baseProduct, submit, footer) => (
          <div>
            <div className="row-flex py-3 pr-3">
              <NavLink to={"/" + this.props.id_boutique + "/products/ingredient"} exact className="btn btn-link">
                <i className="fas fa-chevron-left"></i>
              </NavLink>
              <div className="form-title mr-auto">{!this.props.id ? "Nouvel ingrédient" : "Modifier un ingrédient"}</div>
              <button onClick={submit} className="btn btn-primary">Enregistrer</button>
            </div>
            <div className="p-3">
              <SmartOptionSelect model={baseProduct} name="productCategory" className="mb-3" subOptionName="ingredient salade"/>
              <SmartInputText model={baseProduct} name={"name"} placeholder="Nom du produit" containerClassName="mb-3"/>
              <SmartInputText model={baseProduct.products[0]} name={"reference"} placeholder="Référence" containerClassName="mb-3"/>
              <div className="form-title mb-3">Vente</div>
              <div className="mb-4 d-block d-sm-flex">
                <div className="mr-2 w-100">
                  <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[0]} name={"price"} placeholder="Prix de base" step="0.01" className="mb-3"/>
                </div>
                <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[0]} name={"modifiedPrice"} placeholder="Prix modifié" step="0.01" className="mb-3"/>
              </div>
              <div className="mb-3 row-flex">
                <div className="form-title">Photos de l'ingrédient</div>
              </div>
              <ImageGallery standalone={!!baseProduct.id} unique model={baseProduct} />
              {footer()}
            </div>
          </div>
        )}
      />
    );
  }
}
