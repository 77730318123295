import * as React from 'react';
import { ModalBody } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';
import Order from '../../models/Order';
import SmartInputTextarea from '../input/input/SmartInputTextarea';
import EntityManager from '../../services/EntityManager';
import DOMService from '../../services/DOMService';

export interface ICommentModalProps {
  order: Order
  onSend: any;
}

export interface ICommentModalState {
}

export default class CommentModal extends React.Component<ICommentModalProps, ICommentModalState> {

  async sendComment() {
    await EntityManager.update(this.props.order, {only: ["adminComment"]});
    this.props.onSend();
    DOMService.close();
  }

  public render() {
    return (
      <>
      <CustomModalHeader title="Ajouter un commentaire" />
      <ModalBody>
        <div className="text-secondary mb-3">Commande de {this.props.order.user.getFullName()}</div>
        <SmartInputTextarea model={this.props.order} name="adminComment" placeholder="Commentaire..." />
        <div className="row-end mt-3">
          <button onClick={() => this.sendComment()} className="btn btn-primary">Envoyer</button>
        </div>
      </ModalBody>
      </>
    );
  }
}
