import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ModelAttribute from './core/ModelAttribute';
import Gift from './Gift';

export default class SponsorshipReward extends Model {

  public sort: string;
  public giftId: number;
  public tokens: number;
  public gift: Gift;

  static modelUrl = "sponsorship_rewards";
  public modelName = "sponsorshipReward";

  public attributes = new AttributesCollection({
    sort: new Attribute("sort"),
    giftId: new Attribute("giftId"),
    tokens: new Attribute("tokens"),
    gift: new ModelAttribute("gift", Gift),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}