import { uniqueId } from 'lodash';
import * as React from 'react';
import { ModalTitle } from 'react-bootstrap';
import DOMService from '../../services/DOMService';

export interface ICustomModalHeaderProps {
  title?: string;
  onClose?: () => void
  style?: React.CSSProperties
  actions?: JSX.Element[]
}

export default class CustomModalHeader extends React.Component<ICustomModalHeaderProps> {
  public render() {
    const { title, style, actions } = this.props;
    return (
      <div style={style} className="modal-header">
        <ModalTitle className="pl-2">{title}</ModalTitle>
        <div className="row-end">
          {actions?.map(a => a)}
          <div key={uniqueId()} onClick={() => DOMService.close()} className="close-btn">
            <i className={"far fa-times"}></i>
          </div>
        </div>
      </div>
    );
  }
}
