import * as React from 'react';
import DOMService from '../../services/DOMService';
import LocaleService from '../../services/LocaleService';
import TextIcon from '../common/TextIcon';
import classNames from 'classnames';

export default class DialogBase extends React.Component<any, any> {

  constructor(props) {
    super(props)
    DOMService.dialogRef = this;
    this.state = {
      show: false,
      text: null,
      icon: "",
      closing: false
    }
  }

  onClose = (index) => {
    this.setState({toasts: this.state.toasts.filter((_, i) => index !== i)})
  }

  public show = (modelClass: any, promise: Promise<any>) => {
    let text = LocaleService.toaster(modelClass.modelName, "update")
    this.setState({show: true, text: text})
    promise.then(() => {
      this.setState({text: "Vos données ont été enregistrées !", icon: "fas fa-check-circle text-success"})
    }).catch(() => {
      this.setState({text: "Une erreur est survenue !", icon: "fas fa-times-circle text-danger"})
    }).finally(() => {
      setTimeout(() => {
        this.setState({closing: true});
        setTimeout(() => {
          this.setState({show: false, text: null, closing: false})
        }, 600)
      }, 4000)
    })
  }

  public render() {
    const { text, show, icon, closing } = this.state;
    if (!show) return <></>
    return (
      <div className="base-dialog">
        <div className={classNames({"dialog-body": true, "closing": closing})}>
          <TextIcon leftIcon={icon}>{text}</TextIcon>
        </div>
      </div>
    );
  }
}
