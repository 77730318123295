import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Actuality from '../../models/Actuality';
import SmartInputText from '../input/input/SmartInputText';
import SmartInputTextarea from '../input/input/SmartInputTextarea';
import FormModel, { IFormModelFormProps } from '../logic/FormModel';
import { RouteComponentProps } from 'react-router';
import ImageGallery from '../common/ImageGallery';

export interface IActualityFormProps {
  id?: any
  id_boutique: any
}

export interface IActualityFormState {
  actuality: Actuality
}

class ActualityForm extends React.Component<IActualityFormProps & IFormModelFormProps<Actuality> & RouteComponentProps, IActualityFormState> {

  async submit() {
    let submitted: any = await this.props.submit();
    if (submitted) this.props.history.push("/" + this.props.id_boutique+ "/news")
  }


  public render() {
    const {model, manager} = this.props
    if (!model) return <></>
    return (
      <div>
        <div className="row-flex py-3 pr-3">
          <NavLink to={"/news"} exact className="btn btn-link">
            <i className="fas fa-chevron-left"></i>
          </NavLink>
          <div className="form-title mr-auto">{!model.id ? "Nouvel Actualité" : "Modifier une actualité"}</div>
          <button onClick={() => this.submit()} className="btn btn-primary">Enregistrer</button>
        </div>
        <div className="p-3">
          <SmartInputText name="title" placeholder="Titre" model={model} containerClassName="mb-3"/>
          <SmartInputTextarea name="description" placeholder="Description" model={model} containerClassName="mb-3"/>
          <div className="mb-3 row-flex">
            <div className="form-title">Photos de l'actualité</div>
          </div>
          <ImageGallery standalone={!!model.id} unique model={model} />
          {model.id && <div className="text-center">
            <hr/>
            <div onClick={() => manager.delete(model, () => this.props.history.push("/news"))} className="text-danger mt-2">Supprimer</div>
          </div>}
        </div>
      </div>
    );
  }
}

export default withRouter(FormModel<Actuality>(ActualityForm, {modelClass: Actuality}))