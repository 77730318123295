import * as React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Gift from '../../models/Gift';
import EntityManager from '../../services/EntityManager';
import ImageGallery from '../common/ImageGallery';
import SmartInputNumber from '../input/input/SmartInputNumber';
import SmartInputText from '../input/input/SmartInputText';
import SmartOptionSelect from '../input/input/SmartOptionSelect';
import formModel from '../logic/FormModel';
import ProductModelSelect from '../modelSelect/ProductModelSelect';

export interface IGiftFormProps {
  id?: any
  id_boutique: any
}

export interface IGiftFormState {
}

class GiftForm extends React.Component<any, IGiftFormState> {

  async submit() {
    let valid = await this.props.submit();
    if (valid) this.props.history.push("/" + this.props.id_boutique +"/rewards")
  }

  async delete() {
    await EntityManager.delete(this.props.model);
    this.props.history.push("/rewards")
  }

  public render() {
    const {model} = this.props;
    {console.log(model)}
    if (!model) return <></>
    if (model.giftTypeId != 3) return <div className="text-center text-danger">Ce bon ne peux être modifié</div>
    return (
      <div>
        <div className="row-flex py-3 pr-3">
          <NavLink to={"/" + this.props.id_boutique +"/rewards"} exact className="btn btn-link">
            <i className="fas fa-chevron-left"></i>
          </NavLink>
          <div className="form-title mr-auto">{!this.props.id ? "Création d'un cadeau" : "Modifier un cadeau"}</div>
          <button onClick={() => this.submit()} className="btn btn-primary">Enregistrer</button>
        </div>
        <div className="p-3">
          <SmartInputText name="title" label="Titre" model={model} containerClassName="mb-3"/>
          <SmartOptionSelect name="giftType" label="Type de cadeau" model={model} containerClassName="mb-3"/>
          <div>
            { model.isPercent() && <SmartInputNumber name="percent" label="Pourcentage" model={model} containerClassName="mb-3"/> }
            { model.isAmount() && <SmartInputNumber name="amount" label="Montant" model={model} containerClassName="mb-3"/> }
            { model.isProductFree() && (
              <div>
                <ProductModelSelect name="products" label="Produit offert" model={model} isMulti containerClassName="mb-3"/>
                <small>Sélectionner plusieurs produit rendra le bon valable avec tous ces produits</small>
              </div>
            )}
          </div>
        </div>
        <div className="px-3 mb-5">
          <div className="mb-3">
            <div className="form-title">Photo du cadeau</div>
            <small>Cette photo se retrouvera sur les gains du parrainage et aussi sur la roue de la fortune</small>
          </div>
          <ImageGallery standalone={!!model.id} unique model={model} />
        </div>
        {/* <div className="mt-3">
          <button onClick={() => this.delete()} className="btn btn-danger">Supprimer</button>
        </div> */}
      </div>
    );
  }
}



export default withRouter(formModel(GiftForm, {modelClass: Gift}))