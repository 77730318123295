import { AxiosResponse } from 'axios';
import Model from '../../models/Model';
import EntityManager from '../EntityManager';

export default class ModelCollectionResponse<T extends Model> {

  public models: T[]
  public count: number;
  public ok = false;
  public errors
  public response: AxiosResponse;

  constructor(response: AxiosResponse, modelClass) {
    this.response = response;
    if (response.status === 200) {
      this.ok = true;
      this.models = response.data.models.map(m => EntityManager.toModel(modelClass, m))
      this.count = response.data.count || response.data.models.length
    } else {
      this.errors = response.data.errors;
    }
  }

}