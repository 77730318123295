import React from "react";
import Select from 'react-select';
import OptionService from "../../../services/OptionService";
import InputIcon from '../../common/InputIcon';
import SmartInput, { ISmartInputProps } from './SmartInput';
import Option from '../../../models/Option';
import { OptionAttribute } from '../../../models/core/OptionAttribute';


export interface ISmartOptionSelectProps extends ISmartInputProps {
  subOptionName?: string
}

export default class SmartOptionSelect extends React.Component<ISmartOptionSelectProps, any> {

  public options: Option[];

  constructor(props: any) {
    super(props);

    let optionAttribute = this.props.model.getAttribute(this.props.name) as OptionAttribute;
    this.options = OptionService.get(optionAttribute.optionType, this.props.subOptionName);

  }


  render() {
    return (
      <SmartInput
        {...this.props}
        render={({containerClassName, updateState, inputProps, leftIcon, rightIcon, model, label}) => {
          const selected = this.options.find(o => o.id?.toString() === model[inputProps.name + "Id"]?.toString());
          inputProps.value = selected ? {label: selected.label, value: selected.id?.toString(), option: selected} : null
          return <div className={containerClassName}>
            { (label) && <label htmlFor={ inputProps.id || inputProps.name }>{ label }</label> }
            <InputIcon leftIcon={leftIcon} rightIcon={rightIcon} >
              <Select
                {...inputProps}
                placeholder={inputProps.placeholder || "Choisir"}
                onChange={(value) => updateState(value.option)}
                options={this.options.map((o) => ({label: o.label, value: o.id?.toString(), option: o}))}
              />
            </InputIcon>
          </div>
        }}
      />
    )
  }
}

