import classNames from 'classnames';
import { debounce } from 'lodash';
import * as React from 'react';
import Model from '../../../models/Model';
import EntityManager from '../../../services/EntityManager';

export interface ISmartInputProps {
  model?: Model;
  name?: string;
  value?: string;
  type?: string;
  label?: string;
  floating?: boolean;
  autoSave?: boolean;
  autoFocus?: boolean;
  noMargin?: boolean;
  required?: boolean;
  className?: string;
  containerClassName?: string
  disable?: boolean;
  autoComplete?: string
  placeholder?: string;
  id?: string;
  style?: React.CSSProperties;
  error?: any
  leftIcon?: any
  rightIcon?: any
  onChange?: (e: React.ChangeEvent<HTMLElement>, arg0: any) => void;
  onUpdate?: () => void;
  onModelUpdated?: (arg0: Model) => void;
  onEnter?: () => void
  helpFunction?: (val: string) => string
  debounced?: boolean
}

export interface ISmartInputState {
}

export default class SmartInput<P extends ISmartInputProps, S extends ISmartInputState> extends React.Component<P & {render: any}, S> {

  public debouncedUpdate;
  public debounceWait = 300;
  public targetProperty = "value";

  constructor(props: P & {render: any}) {
    super(props)
    this.debouncedUpdate = props.debounced ? debounce(this.update, this.debounceWait) : this.update;
  }

  public handleChange = (e) => {
    let value = this.handleEvent(e);
    this.updateState(value, e);
  }

  updateState = (value, e) => {
    const { model, name, autoSave, onChange, helpFunction }: P = this.props;
    if (helpFunction) value = helpFunction(value);
    if (model) model[name] = value;
    if (onChange) onChange(e, value);
    if (autoSave) this.debouncedUpdate();
    if (!model?.getAttribute(name).isListened()) this.setState({});
  }

  getErrorMessage() {
    const { model, name }: P = this.props;
    if (!model) return null;
    const attr = model.getAttribute(name);
    if (!attr) return console.error( "Cet attribut n'existe pas");
    return attr.getErrorMessage();
  }

  handleEvent = (e) => {
    return e.target[this.targetProperty];
  }

  componentWillUnmount() {
    if (this.props.debounced) this.debouncedUpdate.cancel();
  }

  public update = async () => {
    const { name, model, onUpdate, onModelUpdated } = this.props;
    if (onUpdate) onUpdate();
    const updatedModel = (await EntityManager.update(model, {only: [name]})).model;
    if (onModelUpdated) onModelUpdated(updatedModel);
  }

  getValue = () => {
    const { model, name }: any = this.props;
    return (model ? model[name] : this.props[this.targetProperty]) ?? "";
  }

  getClassName = () => {
    const {containerClassName} = this.props;
    return classNames({"smart-input": true, [containerClassName]: !!containerClassName})
  }

  render() {
    return this.props.render({
      containerClassName: this.getClassName(),
      handleChange: this.handleChange,
      updateState: this.updateState,
      errorMessage: this.getErrorMessage(),
      model: this.props.model,
      label: this.props.label,
      inputProps: {
        value: this.getValue(),
        className: classNames({[this.props.className]: !!this.props.className, "is-invalid": !!this.getErrorMessage()}),
        name: this.props.name,
        autoFocus: this.props.autoFocus,
        required: this.props.required,
        disable: this.props.disable,
        type: this.props.type,
        autoComplete: this.props.autoComplete,
        placeholder: this.props.placeholder,
        id: this.props.id,
        style: this.props.style,
      }
    });
  }
}
