import * as React from 'react';
import Actuality from '../../models/Actuality';
import recordManager from '../logic/RecordManager';
import ActualityCard from './ActualityCard';
import { NavLink } from 'react-router-dom';
import FragmentLink from '../logic/FragmentLink';
import Loading from '../common/Loading';
import classNames from 'classnames';
import { IInjectedRecordManagerProps } from '../logic/RecordManager';

export interface IActualityListProps extends IInjectedRecordManagerProps<Actuality> {
  id_boutique: any
}

export interface IActualityListState {
}

class ActualityList extends React.Component<IActualityListProps, IActualityListState> {
  constructor(props: IActualityListProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {models} = this.props;
    return (
      <div className="p-3">
        <div className="action-bar row-between mb-3">
          <h4 className="mb-0 font-weight-bold">Actualités</h4>
          <NavLink to={"/" + this.props.id_boutique + "/news/new"} className="btn btn-primary">Nouveau</NavLink>
          <NavLink to={"/" + this.props.id_boutique + "/news/notif"} className="btn btn-primary">Envoyer une notification</NavLink>
        </div>
        {!models && <Loading/>}
        <div className={classNames({"actualities-container fade": true, "show": !!models})}>
          {
            models && models.map(actuality => (
              <FragmentLink key={actuality.id} to={`/${this.props.id_boutique}/news/${actuality.id}/edit`} >
                <ActualityCard actuality={actuality} />
              </FragmentLink>
          ))}
        </div>
      </div>
    );
  }
}

export default recordManager(ActualityList, {modelClass: Actuality, loadOnReady: true})