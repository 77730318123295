import { AxiosResponse } from 'axios';
import User from '../../models/User';
import EntityManager from '../EntityManager';

export default class LoginResponse {

  public user: User;
  public token: string;
  public ok = false;
  public errors

  constructor(response: AxiosResponse) {
    if (response.status === 200) {
      this.ok = true;
      this.user = EntityManager.toModel(User, response.data.user);
      this.token = response.data.token;
    } else {
      this.errors = response.data.errors;
    }
  }

}