import * as React from 'react';
import { NavLink } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import Product from '../../../models/Product';
import OptionService from '../../../services/OptionService';
import ImageGallery from '../../common/ImageGallery';
import SmartInputNumber from '../../input/input/SmartInputNumber';
import SmartInputText from '../../input/input/SmartInputText';
import SmartInputTextarea from '../../input/input/SmartInputTextarea';
import SmartOptionSelect from '../../input/input/SmartOptionSelect';
import ProductFormLogic from './ProductFormLogic';

export interface IPizzaFormProps {
  id?: string
}

export interface IPizzaFormState {
}

export default class PizzaForm extends React.Component<IPizzaFormProps & any, IPizzaFormState> {

  instanceModel() {
    const size26 = OptionService.getOption("size_variant", "size_variant_26");
    const size33 = OptionService.getOption("size_variant", "size_variant_33");
    const size40 = OptionService.getOption("size_variant", "size_variant_40");
    return new BaseProduct({
      products: [
        new Product({size: size26, sizeId: size26.id}),
        new Product({size: size33, sizeId: size33.id}),
        new Product({size: size40, sizeId: size40.id})
      ]
    });
  }

  public render() {
    let newModel = this.instanceModel();
    newModel.setOptionAttributesToDefault();
    newModel.productCategory = OptionService.get("product_category", "pizza").find(o => o.key === "pizza_base_tomate");
    return (
      <ProductFormLogic
        id={this.props.id}
        newModel={newModel}
        render={(baseProduct, submit, footer) => (
          <div>
            <div className="row-flex py-3 pr-3">
              <NavLink to={"/" + this.props.id_boutique + "/products/pizza"} exact className="btn btn-link">
                <i className="fas fa-chevron-left"></i>
              </NavLink>
              <div className="form-title mr-auto">{!this.props.id ? "Création d'une pizza" : "Modifier une pizza"}</div>
              <button onClick={submit} className="btn btn-primary">Enregistrer</button>
            </div>
            <div className="p-3">
              <SmartOptionSelect model={baseProduct} label="Base" name="productCategory" containerClassName="mb-3" subOptionName="pizza"/>
              <SmartInputText model={baseProduct} name={"name"} placeholder="Nom de la pizza" containerClassName="mb-3"/>
              <SmartInputTextarea model={baseProduct} name={"description"} placeholder="Description" rows={5} containerClassName="mb-3"/>
              <div className="row-between mb-3">
                <div className="form-title">Tailles</div>
              </div>
              <div className="mb-3">
                {OptionService.get("size_variant")?.filter(o => o.key !== "size_variant_unique").map((option, index) => (
                  <div key={option.id} className="row-flex d-block d-sm-flex">
                    <div className="mr-3 mb-2">{option.label}</div>
                    <div className="mr-2 mb-2 w-100">
                      <SmartInputText model={baseProduct.products[index]} name={"reference"} placeholder="Référence"/>
                    </div>
                    <div className="mr-2 mb-2 w-100">
                      <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[index]} name={"price"} placeholder="Prix de base" step="0.01"/>
                    </div>
                    <div className="mr-2 mb-2 w-100">
                      <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[index]} name={"modifiedPrice"} placeholder="Prix promotionnel" step="0.01"/>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-3 row-flex">
                <div className="form-title">Photos de la pizza</div>
              </div>
              <ImageGallery standalone={!!baseProduct.id} unique model={baseProduct} />
              {footer()}
            </div>
          </div>
        )}
      />
    );
  }
}
