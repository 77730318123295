import * as React from 'react';
import classNames from 'classnames';

export interface ITextIconProps {
  leftIcon?: string;
  rightIcon?: string;
  spacing?: number;
  iconStyle?: React.CSSProperties;
  iconClassName?: string
  className?: string
  onClick?: () => void
}

export default class TextIcon extends React.Component<ITextIconProps & React.HTMLProps<HTMLDivElement>> {
  public render() {
    const { leftIcon, rightIcon, children, spacing, className, onClick, iconStyle, iconClassName } = this.props;
    return (
      <div onClick={onClick} className={classNames({"row-flex": true,  [className]: !!className})}>
        { leftIcon && <i style={{...iconStyle, width: "30px"}} className={classNames({[leftIcon]: true, [iconClassName]: !!iconClassName})} /> }
        <span>{children}</span>
        { rightIcon && <i style={iconStyle} className={classNames({[rightIcon]: true, [`ml-${spacing || 2}`]: true, [iconClassName]: !!iconClassName})} /> }
      </div>
    );
  }
}
