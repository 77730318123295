import Model from "./Model";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import OptionService from "../services/OptionService";
import ArrayModelAttribute from './core/ArrayModelAttribute';

export default class Option extends Model {

  public optionTypeName: string
  public label: string
  public color: string
  public key: string
  public default: boolean
  public optionId: number
  public active: boolean;
  public options: Option[]

  static modelUrl = "options";
  public modelName = "option";

  public attributes = new AttributesCollection({
    label: new Attribute("label"),
    key: new Attribute("key"),
    optionTypeName: new Attribute("optionTypeName"),
    optionTypeLabel: new Attribute("optionTypeLabel"),
    optionTypeId: new Attribute("optionTypeId"),
    optionId: new Attribute("optionId"),
    color: new Attribute("color"),
    active: new Attribute("active"),
    default: new Attribute("default"),
    options: new ArrayModelAttribute("options", Option),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  isSameOf(key): boolean {
    return OptionService.getOptionId(this.optionTypeName, key) === this.id
  }

}