import * as React from 'react';
import { NavLink } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import recordManager from '../../logic/RecordManager';
import ProductCard from './ProductCard';
import { IInjectedRecordManagerProps } from '../../logic/RecordManager';
import Loading from '../../common/Loading';
import classNames from 'classnames';

export interface IPizzaListProps extends IInjectedRecordManagerProps<BaseProduct> {
  stockMode: boolean
  id_boutique: any
}

export interface IPizzaListState {
}

class PizzaList extends React.Component<IPizzaListProps, IPizzaListState> {

  public render() {
    const { models, stockMode } = this.props;
    return <>
      {!models && <Loading/>}
      <div className={classNames({"fade product-list": true, "show": !!models})}>
        <NavLink to={"/"+ this.props.id_boutique +"/products/pizza/new"} className="new-btn-card">
          <div className="m-auto">Ajouter</div>
        </NavLink>
        {models && models.map((product: BaseProduct) => <>{product.baseProductInfos && <ProductCard stockMode={stockMode} key={product.id} to={`/${this.props.id_boutique}/products/pizza/${product.id}/edit`} product={product} />}</>)}
      </div>
    </>
  }
}

export default recordManager(PizzaList, {
  modelClass: BaseProduct,
  loadOnReady: true,
});