import firebase from 'firebase/app';
import 'firebase/messaging';

class NotificationService {

  public messaging: firebase.messaging.Messaging;
  public token;
  public subscription: firebase.Unsubscribe;
  public supported;

  constructor() {
    this.supported = firebase.messaging.isSupported();
    if (this.supported) {
      let app = firebase.initializeApp({
        apiKey: "AIzaSyDPrJvH8g_ZUsRELyh8bZEYDeS7uAFfykE",
        authDomain: "eurythmeal-321113.firebaseapp.com",
        projectId: "eurythmeal-321113",
        storageBucket: "eurythmeal-321113.appspot.com",
        messagingSenderId: "959921869097",
        appId: "1:959921869097:web:4a6124cebc88d2a378a738"
      });
      this.messaging = app.messaging();
    }
  }

  init = async () => {
    await Notification.requestPermission()
    this.token = await this.messaging.getToken();
    navigator.serviceWorker.addEventListener("message", (message) => {
      this.playNotifSound();
    });
  }

  playNotifSound() {
    let audio = new Audio('https://apieurythmeal.urioz.com/sounds/notif_sound.mp3');
    audio.play();
  }

  getToken = () => {
    return this.messaging.getToken({vapidKey: 'BFVXjgrRZXEZducEax3sPFGAxLFkPloMgEa1m0GRid2yOm6kINjBienzxo4A7QW-QZgNfTabK2XUS0S0oxzPP08'}).then((currentToken) => {
      return currentToken;
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return null;
    });
  }

  onMessageListener = () =>
    new Promise((resolve) => {
      this.subscription = this.messaging.onMessage((payload) => {
        resolve(payload);
      });
  });

}

export default new NotificationService();