import Model from './Model';
import Product from './Product';
import Attribute from './core/Attribute';
import ModelAttribute from './core/ModelAttribute';
import AttributesCollection from './core/AttributeCollection';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Extra from './Extra';

export default class OrderComposition extends Model {

  public productId: number;
  public orderId: number;
  public product: Product;
  public sellPrice: number;
  public quantity: number;
  public extras: Extra[];

  static modelUrl = "order_compositions";
  public modelName = "orderComposition";

  public attributes = new AttributesCollection({
    productId: new Attribute("productId"),
    orderId: new Attribute("orderId"),
    sellPrice: new Attribute("sellPrice"),
    quantity: new Attribute("quantity"),
    product: new ModelAttribute("product", Product),
    extras: new ArrayModelAttribute("extras", Extra),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}