import * as React from 'react';
import Select from 'react-select';
import Product from '../../models/Product';
import EntityManager from '../../services/EntityManager';
import ImageBg from '../common/ImageBg';
import SmartInput from '../input/input/SmartInput';

export interface IProductModelSelectProps {
}

export interface IProductModelSelectState {
}

export default class ProductModelSelect extends React.Component<any, IProductModelSelectState> {

  public models;

  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  async componentDidMount() {
    this.models = (await EntityManager.all(Product)).models;
    this.setState({})
  }

  formatOptionLabel = (model: Product) => {
    return <div className="row-flex">
      <ImageBg size="50px" url={model.baseProduct.image?.url} />
      <div className="ml-3">
        <div >{model.baseProduct.name}</div>
        <div className="text-secondary">{model.size?.label}</div>
      </div>
    </div>
  }

  public render() {
    const { model, placeholder, name, isMulti } = this.props;
    if (!this.models) return <></>
    return (
      <SmartInput
        {...this.props}
        render={({getClassName, updateState, getValue}) => (
          <Select
            placeholder={placeholder || "Choisir"}
            onChange={(value) => updateState(value)}
            value={model[name]}
            isMulti={isMulti}
            getOptionValue={(c) => c.id}
            formatOptionLabel={this.formatOptionLabel}
            options={this.models}
          />
        )}
      />
    );
  }
}
