import * as React from 'react';
import recordManager, { IInjectedRecordManagerProps } from '../logic/RecordManager';
import User from '../../models/User';
import { NavLink } from 'react-router-dom';
import SearchInput from '../input/input/SearchInput';
import dayjs from 'dayjs';
import TextIcon from '../common/TextIcon';
import Loading from '../common/Loading';

export interface ICustomerListProps extends IInjectedRecordManagerProps<User> {
  router: any
  id_boutique: string
}

export interface ICustomerListState {
}

class CustomerList extends React.Component<ICustomerListProps, ICustomerListState> {

  public render() {
    
    const {models, totalCount, search, input} = this.props;
    if (!models) return <Loading/>
    return (
      <div>
        <div className="row-between p-3">
          <div className="row-flex">
            <NavLink to="" exact className="btn btn-primary" onClick={this.props.router.history.goBack}>Retour</NavLink>
            <SearchInput onClear={() => this.props.manager.clearSearch()} innerRef={input} onChanged={(val) => this.props.manager.handleSearch(val)} onSearch={() => this.props.manager.onSearch()} value={search} />
          </div>
        </div>
        <div className="p-3">
          <h5 className="mb-0 font-weight-bold">{totalCount} clients</h5>
        </div>
        <div className="actualities-container px-3">
          { models.map(customer => (
            <div className="user-card">
              <div className="col-between h-100">
                <h6 className="font-weight-bold capitalize">{customer.getFullName()}</h6>
                <div className="text-secondary">Incris depuis le {dayjs(customer.createdAt).format("D MMMM YYYY")}</div>
                {customer.activated ? <div className="border-top pt-3 mt-3">
                  <TextIcon leftIcon="fa fa-envelope">{customer.email}</TextIcon>
                  <TextIcon leftIcon="fa fa-map-marker-alt">{customer.address}</TextIcon>
                  {customer.ordersCount > 0 ? <div>{customer.ordersCount} commandes</div> : "Aucune commande"}
                  {customer.sponsorshipCount > 0 && <div>{customer.sponsorshipCount} filleuls</div>}
                  {customer.sponsorshipCount > 0 && <div>{customer.usedToken} jetons utilisés</div>}
                </div> : <div className="alert alert-danger mt-3 mb-0">Compte non activé</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default recordManager(CustomerList, {modelClass: User, loadOnReady: true})