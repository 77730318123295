import * as React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { NavLink } from 'react-router-dom';
import IconCard from '../components/dashboard/IconCard';
import ApiService from '../services/ApiService';
import chartService from '../services/chartService';

export interface IAdminDashboardPageProps {
  router: any
  id_boutique: string
}

export interface IAdminDashboardPageState {
  totalAmount: any
  totalOrder: any
  isLoaded: any
  sellByCategory: any
  ecotreeCount: any
  sellByMonth
}

export default class AdminDashboardPage extends React.Component<IAdminDashboardPageProps, IAdminDashboardPageState> {
  constructor(props: IAdminDashboardPageProps) {
    super(props);

    this.state = {
      totalAmount: null,
      totalOrder: null,
      sellByMonth: null,
      sellByCategory: null,
      ecotreeCount: null,
      isLoaded: false
    }
  }

  async componentDidMount() {
    let response = await ApiService.get("admin/" + this.props.id_boutique + "/dashboard");
    let {totalAmount, totalOrder, sellByCategory, ecotreeCount, sellByMonth} = response.data;
    sellByCategory  = chartService.buildData(sellByCategory);
    sellByMonth  = chartService.buildSellByMonth(sellByMonth);
    // sellEvolution   = chartService.buildEvolutionData(sellEvolution);
    this.setState({totalAmount, totalOrder, isLoaded: true, ecotreeCount, sellByMonth, sellByCategory});
  }

  resetEcotree = async (e) => {
    await ApiService.post("reset_ecotree", {});
    this.setState({ecotreeCount: 0})
  }

  public render() {
    const {totalAmount, totalOrder, isLoaded, sellByCategory, ecotreeCount, sellByMonth} = this.state;
    if (!isLoaded) return <></>
    
    return (
      <div className="p-3">
        <div className="row-flex mb-3">
          <h4 className="mb-0 font-weight-bold mr-auto">Statistiques</h4>
          <div className="mr-2">
            <NavLink className="btn btn-light rounded-pill" to={"/" +this.props.id_boutique + "/charts/customers"}>
              <span className="d-none d-sm-block">Tous les clients</span>
              <i className="fas fa-users d-block d-sm-none"></i>
            </NavLink>
          </div>
          <div className="mr-2">
            <NavLink className="btn btn-light rounded-pill" to={"/" +this.props.id_boutique + "/charts/notices"}>
              <span className="d-none d-sm-block">Voir les avis</span>
              <i className="fas fa-star d-block d-sm-none"></i>
            </NavLink>
          </div>
          <div className="">
            <NavLink className="btn btn-light rounded-pill" to={"/" +this.props.id_boutique + "/settings"}>
              <span className="d-none d-sm-block">Paramètres</span>
              <i className="fas fa-cog d-block d-sm-none"></i>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12 mb-3">
            <div className="mb-2">
              <IconCard label="Vente Totale" text={totalAmount + " €"} icon="cart-plus" color="blue" linkLabel={"voir les " + totalOrder + "commandes"} linkHref="/orders" />
            </div>
            <div className="mb-2">
            <IconCard label="Compteur Ecotree" text={ecotreeCount} icon="acorn" color="green"  />
            </div>
            <button onClick={this.resetEcotree} className="btn btn-primary">Reset Compteur</button>
          </div>
          <div className="col-lg-8 col-12 mb-3">
            <table className="table">
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Pizza</td>
                  <td>Boisson</td>
                  <td>Pti creux</td>
                  <td>Dessert</td>
                </tr>
              </thead>
              <tbody>
                {sellByMonth.map(row => (
                  <tr>
                    <td>{row.date}</td>
                    <td>{new Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(row.pizza)}</td>
                    <td>{new Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(row.drink)}</td>
                    <td>{new Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(row.peckish)}</td>
                    <td>{new Intl.NumberFormat("fr-FR", { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(row.dessert)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="col-lg-4 col-12 mb-3">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Ventes par catégorie</div>
              </div>
              <div className="">
                <Doughnut data={sellByCategory} />
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Evolution des ventes</div>
              </div>
              <div className="">
                <Bar
                  data={sellEvolution}
                  height={400}
                  options={{
                    maintainAspectRatio: false,
                    legend: {
                      display: false
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
