import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import BaseProduct from '../../../models/BaseProduct';
import ImageBg from '../../common/ImageBg';
import SmartSwitch from '../../input/input/SmartSwitch';

export interface IProductCardProps {
  product: BaseProduct
  to: string
  stockMode: boolean
}

export interface IProductCardState {
}

class ProductCard extends React.Component<IProductCardProps & RouteComponentProps, IProductCardState> {

  static defaultProps = {
    isNotInShop: false
  };

  public render() {
    const {product, to, stockMode} = this.props;
    return (
      <div className="pizza-card">
          <div className="product-image" style={{opacity: product.available ? 1 : 0.5}} onClick={() => this.props.history.push(to)}>
            <ImageBg url={product.image?.url} height="100%" width="100%" />
          </div>
          <h6 onClick={() => this.props.history.push(to)} style={{opacity: product.available ? 1 : 0.5}} className="font-weight-bold text-center mt-2">{product.name}</h6>
          {stockMode && <div className="row-center">
            <div className="mr-3">Disponible</div>
            <SmartSwitch model={product} name="available" onModelUpdated={() => this.setState({})} autoSave />
          </div>}
        </div>
    );
  }
}
export default withRouter(ProductCard);