import { groupBy } from "lodash";

class ChartService {

  public yearLabel = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  public yearBackgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
  ];
  public yearBorderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
  ];

  buildData(json: any[]) {
    let labels = []
    let data = []
    json.forEach(d => {
      labels.push(d.label)
      data.push(d.total)
    });
    return {
      datasets: [{
        data: data,
        backgroundColor: this.yearBackgroundColor,
      }],
      labels: labels
    }
  }

  buildSellByMonth(json) {
    let data = []
    let month= ["Janvier","Février","Mars","avril","Mai","Juin","Juillet",
    "Aout","Septembre","Octobe","Novembre","Décembre"];
    json.forEach(row => {
      let date = month[row.month - 1] + " " + row.year;
      let savedRow = data.find(d => d.date === date);
      if (!savedRow) {
        savedRow = {date, pizza: 0, drink: 0, peckish: 0, dessert: 0}
        data.push(savedRow);
      }
      if (row.key === "pizza_base_tomate") savedRow.pizza += parseFloat(row.total) / 100;
      if (row.key === "pizza_base_creme") savedRow.pizza += parseFloat(row.total) / 100;
      if (row.key === "drink") savedRow.drink += parseFloat(row.total) / 100;
      if (row.key === "peckish") savedRow.peckish += parseFloat(row.total) / 100;
      if (row.key === "dessert") savedRow.dessert += parseFloat(row.total) / 100;
    })
    
    return data;
  }

  buildEvolutionData(json: any[]) {
    let data = []
    let month: any = groupBy(json, "month")
    for (let index = 1; index <= 12; index++) {
      if (month[index]) data.push(month[index][0].total)
      else             data.push(0)
    }
    return {
      labels: this.yearLabel,
      datasets: [{
        data,
        backgroundColor: this.yearBackgroundColor,
        borderColor: this.yearBorderColor,
        borderWidth: 1
      }]
    }
  }
}

export default new ChartService();