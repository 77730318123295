import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import FileAttribute from './core/FileAttribute';

export default class ImageModel extends Model {

  public url: string;
  public type: string;
  public main: boolean;
  public imageableType: string;
  public imageableId: number;

  public file: File;

  static modelUrl = "images";
  public modelName = "image";
  // public submitInFormData = true;

  public attributes = new AttributesCollection({
    url: new Attribute("url", {submittable: false}),
    type: new Attribute("type"),
    main: new Attribute("main"),
    imageableType: new Attribute("imageableType"),
    imageableId: new Attribute("imageableId"),
    file: new FileAttribute("file"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  static of(parentModel: Model, json) {
    return new ImageModel({imageableType: parentModel.getBackendModelType(), imageableId: parentModel.id, ...json});
  }

  loadPreview = (file: File): Promise<string | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      let reader: FileReader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      }
      reader.readAsDataURL(file)
    })
  }

}