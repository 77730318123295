import classNames from 'classnames';
import * as React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import SmartInputText from '../components/input/input/SmartInputText';
import LoginResponse from '../services/apiResponse/LoginResponse';
import authService from '../services/AuthService';
import NotificationService from '../services/NotificationService';
import SessionService from '../services/SessionService';

export interface ILoginPageProps {
}

export interface ILoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: boolean
  errors: {[name: string]: string}
}

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isChecking: false,
      redirect: false,
      errors: null
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value, errors: null})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  onLogin = async (response: LoginResponse) => {
    if (response.ok) {
      if (NotificationService.supported) SessionService.initDevice();
      this.setState({redirect: true});
    } else {
      this.setState({errors: response.errors, isChecking: false});
    }
  }

  submit = async (e) => {
    e.preventDefault();
    let response: LoginResponse = await authService.login({email: this.state.email, password: this.state.password})
    this.onLogin(response)
  }

  public render() {
    const {email, password, redirect, errors} = this.state;
    if (redirect) return <Redirect to="/" />
    return (
      <div className="d-flex flex-column" style={{height: "100vh"}}>
        <div className="text-center mb-4">
          <NavLink to="/">
            <img src="https://scontent-mrs2-2.xx.fbcdn.net/v/t1.0-9/122453342_720511908581199_7665523639968978297_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=5Nxl-BEb4gIAX9xhnBP&_nc_ht=scontent-mrs2-2.xx&oh=767d455b925dde85bcfbedaf93deba62&oe=6082C36B" className="logo mb-4" alt=""/>
          </NavLink>
        </div>
        <div className="mx-auto px-3">
          <h2 className="text-center mb-4 font-weight-bold">Connexion</h2>
          <form onSubmit={this.submit}>
            <SmartInputText type="email" className={classNames({"mb-3": true, "is-invalid": !!errors})} placeholder="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus/>
            {errors && <div className="text-danger text-center mt-2">{errors.credential}</div>}
            <SmartInputText id="password" type="password" className={classNames({"mb-3": true, "is-invalid": !!errors})} placeholder="Mot de passe" name="password" value={password} required onChange={this.handleChange}/>
            <div className="pt-2 text-center">
              <NavLink to="/forgot_password">Mot de passe oublié ?</NavLink>
            </div>
            <div className="form-group mb-0 pt-3 text-center">
              <button type="submit" className="btn btn-primary btn-block mt-4">
                SE CONNECTER
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
