import humps from 'lodash-humps';
import { v4 as uuidv4 } from 'uuid';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import FileAttribute from './core/FileAttribute';


export default class Model {

  public id: any;
  public modelName: string;
  static modelUrl: string;
  public createdAt: Date;
  public updatedAt: Date;
  public parentModel: Model;
  public requiredField: string[] = []
  public uuid: string;

  public attributes: AttributesCollection;

  public constructor(json: any, parentModel?: Model) {
    this.id          = json?.id;
    this.uuid        = uuidv4()
    this.createdAt   = json.createdAt && new Date(json.createdAt);
    this.updatedAt   = json.updatedAt && new Date(json.updatedAt);
    this.parentModel = parentModel;
  }

  public setOptionAttributesToDefault() {
    this.attributes.setDefaultValues();
  }

  protected fillAttributes(json) {
    json = humps(json)
    this.attributes.fillWithJson(this, json)
  }

  public isRequired(field: string) {
    return this.requiredField.includes(field);
  }

  public getBackendModelType(): string {
    return `App\\Models\\${this.modelName.charAt(0).toUpperCase() + this.modelName.slice(1)}`;
  }

  public getFrontUrl(): string {
    let classs: any = this.constructor;
    return `/${classs.modelUrl}/${this.id}`;
  }

  public getElementId() {
    return this.modelName + this.id;
  }

  public getApiParam(only?: string[]) {
    return this.attributes.getApiParam(this, only)
  }

  public onChange(callback) {
    this.attributes.listenAllChanges(callback);
  }

  public getAttribute(name: string): Attribute {
    return this.attributes.attributes[name];
  }

  public hasFileAttribute() {
    return Object.values(this.attributes.attributes).find(a => a instanceof FileAttribute) !== undefined;
  }

  public checkForSubmit(): boolean {
    return this.attributes.checkForSubmit();
  }

}

