import Gift from './Gift';
import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ModelAttribute from './core/ModelAttribute';

export default class UserGift extends Model {

  public expireAt: Date
  public orderId: number
  public userId: number
  public giftId: number
  public gift: Gift;
  public percentReduc: number;
  static modelUrl = "user_gifts";
  public modelName = "userGift";


  public attributes = new AttributesCollection({
    expireAt: new Attribute("expireAt"),
    orderId: new Attribute("orderId"),
    userId: new Attribute("userId"),
    percentReduc: new Attribute("percentReduc"),
    giftId: new Attribute("giftId"),
    gift: new ModelAttribute("gift", Gift)
  });

  constructor(json) {
    super(json)
    console.log(json)
    this.fillAttributes(json);
  }

}