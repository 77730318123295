import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import Loading from '../../common/Loading';
import recordManager from '../../logic/RecordManager';
import ProductCard from './ProductCard';

export interface IDrinkListProps {
  id_boutique: any
}

export interface IDrinkListState {
}

class DrinkList extends React.Component<any, IDrinkListState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { models, stockMode } = this.props;
    return <>
      {!models && <Loading/>}
      <div className={classNames({"fade product-list": true, "show": !!models})}>
        <NavLink to={"/"+ this.props.id_boutique + "/products/drink/new"} className="new-btn-card">
          <div className="m-auto">Ajouter</div>
        </NavLink>
        {models && models.map((product: BaseProduct) => <ProductCard stockMode={stockMode} to={`/${this.props.id_boutique}/products/drink/${product.id}/edit`} product={product} />)}
      </div>
    </>
  }
}

export default recordManager(DrinkList, {
  modelClass: BaseProduct,
  loadOnReady: true,
});
