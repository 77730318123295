import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ImageModel from './ImageModel';
import Model from './Model';
import RequiredValidator from './core/form/validators/RequiredValidator';

export default class Actuality extends Model {

  public title: string
  public description: string
  public imageUrl: string
  public images: ImageModel[];

  static modelUrl = "actualities";
  public modelName = "actuality";

  public attributes = new AttributesCollection({
    title: new Attribute("title", {validator: RequiredValidator}),
    description: new Attribute("description"),
    imageUrl: new Attribute("imageUrl"),
    images: new ArrayModelAttribute("images", ImageModel),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}