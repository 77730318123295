import ModelAttribute from "./ModelAttribute";
import Option from '../Option';
import Model from "../Model";
import OptionService from "../../services/OptionService";


export class OptionAttribute extends ModelAttribute {

  public idValue: any;
  public optionType: string

  constructor(name: string, options?) {
    super(name, Option, options);
    this.optionType = options?.optionType || this.resolveOptionType();
    this.key = this.name + "Id";
  }

  public fill(parentModel: any, json) {
    super.fill(parentModel, json)
    let nameOfId = this.key;
    parentModel.getAttribute(this.name).setIdValue(json[nameOfId]);
    Object.defineProperty(parentModel, nameOfId, {
      get: () => {
        return parentModel.getAttribute(this.name).getIdValue();
      },
      set: (val) => {
        let previousValue = parentModel.getAttribute(this.name).getIdValue();
        parentModel.getAttribute(this.name).setIdValue(val);
        let newValue = parentModel.getAttribute(this.name).getIdValue();
        this.change(previousValue, newValue);
      }
    });
  }

  setDefault() {
    let option = OptionService.getDefault(this.optionType);
    this.value = option;
    this.idValue = option.id;
  }

  public change(previousValue?, newValue?) {
    if (newValue instanceof Option) this.idValue = newValue.id;
    super.change(previousValue, newValue);
  }

  resolveOptionType() {
    return this.name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  public toApiData(parentModel: Model, stringify = false) {
    return parentModel[this.key];
  }

  setIdValue(value) {
    return this.idValue = value;
  }

  getIdValue() {
    return this.idValue;
  }

  checkForSubmit() {
    if (!this.validator) return true;

    let valid = this.validator.check(this.getValue());
    if (!valid) {
      this.errors = this.validator.getError();
      this.change();
    }
    return valid;
  }

  hasFileAttribute() {
    return false;
  }
}