import React from "react";
import Select from 'react-select';
import InputIcon from '../../common/InputIcon';
import SmartInput, { ISmartInputProps } from './SmartInput';


export interface ISmartSelectProps extends ISmartInputProps {
  options: any[]
  labelName?: string
  valueName?: string
}

export default class SmartSelect extends React.Component<ISmartSelectProps, any> {

  render() {
    const { options, labelName, valueName, name } = this.props;
    let _labelName = labelName || "label";
    let _valueName = valueName || "value";

    return (
      <SmartInput
        {...this.props}
        render={({containerClassName, updateState, inputProps, label, leftIcon, rightIcon, model}) => (
          <div className={containerClassName}>
            { (label) && <label htmlFor={ inputProps.id || name }>{ label }</label> }
            <InputIcon leftIcon={leftIcon} rightIcon={rightIcon} >
              <Select
                {...inputProps}
                placeholder={inputProps.placeholder || "Choisir"}
                onChange={(value) => updateState(value.value)}
                value={options.find(o => o[_valueName]?.toString() === model[name]?.toString())}
                options={options.map((o) => ({label: o[_labelName], value: o[_valueName]}))}
              />
            </InputIcon>
          </div>
        )}
      />
    )
  }
}

