import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Model from "./Model";
import ProductComposition from './ProductComposition';
import { OptionAttribute } from './core/OptionAttribute';
import Option from './Option';
import BaseProduct from './BaseProduct';
import ModelAttribute from './core/ModelAttribute';
import RequiredValidator from './core/form/validators/RequiredValidator';

export default class Product extends Model {

  public mainImageUrl: string;
  public price: number;
  public modifiedPrice: number;
  public reference: string;
  public available: number;
  public stockMini: number;
  public baseProduct: BaseProduct;
  public size: Option

  public productCompositions: ProductComposition[]

  public requiredField = ["name"]
  static modelUrl = "products";
  public modelName = "product";

  public attributes = new AttributesCollection({
    price: new Attribute("price",{validator: RequiredValidator}),
    modifiedPrice: new Attribute("modifiedPrice"),
    reference: new Attribute("reference",{validator: RequiredValidator}),
    available: new Attribute("available"),
    stockMini: new Attribute("stockMini"),
    size: new OptionAttribute("size"),
    baseProduct: new ModelAttribute("baseProduct", BaseProduct),
    productCompositions: new ArrayModelAttribute("productCompositions", ProductComposition),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }


  getPrice() {
    return this.price + " €";
  }

}












