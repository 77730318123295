import SessionService from '../services/SessionService';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import DeliveryAddress from './DeliveryAddress';
import Model from './Model';
import Order from './Order';
import WishlistItem from './WishlistItem';

export default class User extends Model {

  public firstname: string
  public lastname: string
  public email: string
  public phone: string
  public type: string
  public password: string;
  public activated: boolean;
  public address: string;
  public deliveryAddresses: DeliveryAddress[]
  public orders: Order[];
  public ordersCount: number;
  public sponsorshipCount: number;
  public usedToken: number;
  public wishlistItems: WishlistItem[];
  public guestId: string
  public selectedAddress: DeliveryAddress;
  public deviceTokens: any[]
  public shops: any[]

  static modelUrl = "users";
  public modelName = "user";

  public attributes = new AttributesCollection({
    firstname: new Attribute("firstname"),
    lastname: new Attribute("lastname"),
    address: new Attribute("address"),
    email: new Attribute("email", {submittable: false}),
    phone: new Attribute("phone"),
    type: new Attribute("type"),
    deviceTokens: new Attribute("deviceTokens"),
    activated: new Attribute("activated"),
    ordersCount: new Attribute("ordersCount", {submittable: false}),
    password: new Attribute("password", {submittable: false}),
    deliveryAddresses: new ArrayModelAttribute("deliveryAddresses", DeliveryAddress, {submittable: false}),
    wishlistItems: new ArrayModelAttribute("wishlistItems", WishlistItem, {submittable: false}),
    orders: new ArrayModelAttribute("orders", Order, {submittable: false}),
    shops: new Attribute("shops")
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  public hasDeliveryAddress(): boolean {
    return !!this.deliveryAddresses && this.deliveryAddresses.length > 0;
  }

  public hasCreditCard(): boolean {
    return true;
  }

  public getCreditCard() {
  }

  public getFullName(): string {
    let str = [this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  public isMe = (): boolean => SessionService.user.id === this.id;

  public isAdmin() {
    return this.type === "admin";
  }

  public isDeliveryMan() {
    return this.type === "delivery_man";
  }

  public isCustomer() {
    return this.type === "customer";
  }


}