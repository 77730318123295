import * as React from 'react';
import Select from 'react-select';
import Gift from '../../models/Gift';
import EntityManager from '../../services/EntityManager';
import SmartInput from '../input/input/SmartInput';

export interface IGiftModelSelectProps {
}

export interface IGiftModelSelectState {
}

export default class GiftModelSelect extends React.Component<any, IGiftModelSelectState> {

  public models

  constructor(props: any) {
    super(props);

    this.models = props.models;

    this.state = {
    }
  }

  async componentDidMount() {
    if (this.models) return;
    this.models = (await EntityManager.all(Gift)).models;
    this.setState({})
  }

  formatOptionLabel = (model: any) => {
    return <div className="d-flex">
      {model.title}
    </div>
  }

  public render() {
    const { isMulti } = this.props;
    if (!this.models) return <></>
    return (
      <SmartInput
        {...this.props}
        render={({containerClassName, updateState, inputProps, model}) => {
          const selected = this.models.find(o => o.id?.toString() === model[inputProps.name]?.toString());
          const value = selected ? {label: selected.title, value: selected.id?.toString(), option: selected} : null
          return <div className={containerClassName}>
            <Select
              placeholder={inputProps.placeholder || "Choisir"}
              onChange={(value: any) => updateState(value.option.id)}
              value={value}
              isMulti={isMulti}
              className={inputProps.className}
              // formatOptionLabel={this.formatOptionLabel}
              options={[...this.models, {title: "Aucun", id: null}].map((o) => ({label: o.title, value: o.id?.toString(), option: o}))}
            />
          </div>
        }}
      />
    );
  }
}
