import * as React from 'react';
import { Redirect } from 'react-router';
import authService from '../services/AuthService';


export default class LogoutPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLogout: false
    }
  }

  componentDidMount() {
    authService.logout();
    this.setState({isLogout: true});
  }

  public render() {
    if (!this.state.isLogout) return <div className="logout-page"></div>
    return (
      <Redirect to="/login" />
    );
  }
}
