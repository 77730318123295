
import React from 'react';
import Model from '../../models/Model';
import ModelResponse from '../../services/apiResponse/ModelResponse';
import EntityManager from '../../services/EntityManager';
import recordManager, { IInjectedRecordManagerProps } from './RecordManager';

export interface IFormModelProps<M extends Model> extends IInjectedRecordManagerProps<M> {
  id?: any
  newModel?: M
}

export interface IInjectedFormModelProps<M extends Model> {
  model: M
  submit: () => Promise<void>
}

export interface IFormModelOptions {
  modelClass: any
}

const formModel = <P extends IFormModelProps<Model> & IInjectedFormModelProps<Model>>(Component: React.ComponentType<P>, options: IFormModelOptions) => {

  let component = class extends React.Component<P & IInjectedFormModelProps<Model>, any> {

    public model: Model
    public validators;


    constructor(props: any) {
      super(props)

      this.state = {
      }
    }

    async componentDidMount() {
      let model;
      if (this.props.id) {
        model = (await EntityManager.show(options.modelClass, this.props.id)).model;
      } else if (this.props.newModel) {
        model = this.props.newModel;
      } else {
        model = new options.modelClass({});
        model.setOptionAttributesToDefault();
      }
      model.onChange(() => this.setState({}));
      this.model = model;
      this.setState({});
    }

    submit = async () => {
      if (!this.model.checkForSubmit()) {
        return false;
      }
      let response: ModelResponse<Model> = await this.props.manager.updateOrCreate(this.model);
      if (!response.ok) {
        return false;
      }
      return true;
    }

    public render() {
      return (
        <Component {...this.props as P} model={this.model} submit={this.submit} />
      )
    }
  }

  return recordManager(component, {modelClass: options.modelClass})
}


export default formModel;

