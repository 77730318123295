import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import { OptionAttribute } from './core/OptionAttribute';
import Model from "./Model";
import Option from './Option';
import Product from './Product';
import ImageModel from './ImageModel';
import ModelAttribute from './core/ModelAttribute';
import RequiredValidator from './core/form/validators/RequiredValidator';
import OptionService from '../services/OptionService';

export default class BaseProductInfo extends Model {

    public available: boolean;


    static modelUrl = "base_product_infos";
    public modelName = "baseProductInfo";

    public attributes = new AttributesCollection({
        available: new Attribute("available"),
    });

    constructor(json) {
        super(json)
        this.fillAttributes(json);
    }

}












