import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

export interface IFragmentLinkProps {
  to: string
}

export interface IFragmentLinkState {
}

class FragmentLink extends React.Component<IFragmentLinkProps & RouteComponentProps, IFragmentLinkState> {
  constructor(props: IFragmentLinkProps & RouteComponentProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div onClick={() => this.props.history.push(this.props.to)}>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(FragmentLink)