import classNames from 'classnames';
import * as React from 'react';
import { ISmartInputProps } from './SmartInput';
import InputIcon from '../../common/InputIcon';

export interface ISearchInputProps {
  onSearch: () => void
  value: string
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChanged?: (arg: string) => void
  onClear?: () => void
  placeholder?: string
  className?: string
  innerRef?: React.RefObject<HTMLInputElement>
}

export default class SearchInput extends React.Component<ISearchInputProps & ISmartInputProps, any> {

  public debouncedCall

  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
    };
  }

  onChange = (e) => {
    let value: string = e.target.value;
    if (this.props?.onChanged) this.props.onChanged(value);
  }

  onFocus = (e) => {
    if (this.props?.onFocus) this.props?.onFocus(e);
    this.setState({isFocus: true});
  }

  onIconClick = () => {
    if (this.props.onClear && this.props.value) this.props.onClear();
  }

  public render() {
    return (
      <div className={this.props.className || ""}>
        <InputIcon leftIcon={<i onClick={this.onIconClick} style={{cursor: "pointer"}} className={classNames({"fas fa-search": !this.props.value, "fas fa-times": !!this.props.value, "is-focus": this.state.isFocus})} />} >
          <input className="form-control" onKeyPress={(e) => { if (e.key === "Enter") this.props.onSearch()}} ref={this.props.innerRef} placeholder={this.props.placeholder} value={this.props.value} onChange={this.onChange} onFocus={this.onFocus} onBlur={() => this.setState({isFocus: false})}  />
        </InputIcon>
      </div>
    );
  }
}
