import React from "react";
import InputIcon from '../../common/InputIcon';
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartInputTextProps extends ISmartInputProps {
  maxLength?: number
  minLenght?: number
}

export default function SmartInputText(props: ISmartInputTextProps) {

  const {
    maxLength,
    minLenght
  } = props;
  return (
    <SmartInput
      {...props}
      render={({leftIcon, containerClassName, handleChange, label, inputProps, rightIcon}) => (
        <div className={containerClassName}>
          { (label) && <label htmlFor={ inputProps.id || inputProps.name }>{ label }</label> }
          <InputIcon leftIcon={leftIcon} rightIcon={rightIcon} >
            <input
              {...inputProps}
              className={inputProps.className + " form-control"}
              onKeyDown={ (e) => { if (e.key === "Enter" && props.onEnter) props.onEnter() } }
              onChange={ handleChange }
              maxLength={maxLength}
              minLength={minLenght}
            />
          </InputIcon>
          {/* { error && <div className="error-message">{error}</div>} */}
        </div>
      )}
    />
  );
}

