import { AxiosResponse } from "axios";
import User from "../models/User";
import ApiService from "../services/ApiService";
import { Credential } from "../services/AuthService";
import LoginResponse from "./apiResponse/LoginResponse";
import EntityManager from "./EntityManager";
import ModelResponse from "./apiResponse/ModelResponse";

export default class UserManager {


  public async login(credential: Credential): Promise<LoginResponse> {
    let response: AxiosResponse;
    try {
      response = await ApiService.post(`users/login`, credential);
    } catch (error) {
      response = error.response
    }
    return new LoginResponse(response);
  }

  public async signin(params) {
    return await EntityManager.post<User>(User, {params});
  }

  public async forgotPassword(email: string) {
    let response: AxiosResponse = await ApiService.post(`users/forgotPassword`, {email});
    return response;
  }

  public async me(): Promise<ModelResponse<User>> {
    return await EntityManager.get<User>(User, {path: "me"});
  }

}
