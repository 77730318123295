import ApiService from "./ApiService";

class LocaleService {

  private locale;

  async loadLocale() {
    this.locale = (await ApiService.get("admin/locale")).data;
    return this.locale;
  }

  toaster(modelName: string, key: string) {
    try {
      return this.locale.toaster[modelName][key];
    } catch (error) {
      return "Traduction manquante";
    }
  }
}

export default new LocaleService();