import dayjs from 'dayjs';
import * as React from 'react';
import Order from '../../../models/Order';

export interface IOrderShowProps {
  order: Order
}

export interface IOrderShowState {
}

export default class OrderShow extends React.Component<IOrderShowProps, IOrderShowState> {


  public render() {
    const {order} = this.props;
    if (!order) return <></>
    return (
      <div>
        <div className="text-secondary">
          {"Créé le " + dayjs(order.createdAt).format("dddd D MMMM YYYY à hh:mm")}
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <div className="address">{ order.address.address }</div>
            <div className="city">{ order.address.cpAndCity() }</div>
          </div>
          <div className="col-md-6">
            <div className="row-between mb-3">
              <div>Sous-total </div>
              <div>{ order.subTotal} €</div>
            </div>
            <div className="row-between">
              <div>Livraison</div>
              <div>{ order.shippingCost} €</div>
            </div>
            <hr/>
            <div className="row-between">
              <div>
                <b>Total (TVA incluse)</b>
              </div>
              <div>{ order.totalttc} €</div>
            </div>
          </div>
        </div>
        <div className="">
          Compositions
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Product</th>
              {/* {order.declination.declinationValues.map(val => (
              ))} */}
              <th>Montant</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            { order.orderCompositions.map(compo => (
              <tr>
                <td>
                  <img src="" alt=""/>
                </td>
                <td>
                  <div className="">{compo.product.name}</div>
                </td>
                {/* {compo.declination.declinationValues.map(val => (
                  <td>
                    <div className="">{val.value}</div>
                  </td>
                ))} */}
                <td>
                  <div className="">{compo.sellPrice} €</div>
                </td>
                <td>
                  <div className="">{compo.quantity}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
