import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductForm from '../components/admin/products/ProductForm';
import ProductsPage from '../components/admin/products/ProductsPage';
import IngredientForm from '../components/admin/products/IngredientForm';
import PizzaForm from '../components/admin/products/PizzaForm';
import OptionService from '../services/OptionService';
import IngredientSaladeForm from '../components/admin/products/IngredientSaladeForm';

export interface IAdminProductsPageProps {
  router: any,
  id_boutique: any
}

export interface IAdminProductsPageState {
}

class AdminProductsPage extends React.Component<IAdminProductsPageProps, IAdminProductsPageState> {

  getOption(category: string) {
    return OptionService.getOption("product_category", category);
  }

  public render() {

    const peckish = this.getOption("peckish");
    const drink = this.getOption("drink");
    const dessert = this.getOption("dessert");

    return (
      <Switch>
        <Route exact path="/:id_boutique/products/pizza/new"           component={(p) => <PizzaForm id={p.match.params.id} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/pizza/:id/edit"      component={(p) => <PizzaForm id={p.match.params.id} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/peckish/new"         component={(p) => <ProductForm productCategory={peckish} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/peckish/:id/edit"    component={(p) => <ProductForm id={p.match.params.id} productCategory={peckish} id_boutique={this.props.id_boutique}/>} />
        <Route exact path="/:id_boutique/products/drink/new"           component={(p) => <ProductForm productCategory={drink} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/drink/:id/edit"      component={(p) => <ProductForm id={p.match.params.id} productCategory={drink} id_boutique={this.props.id_boutique}/>} />
        <Route exact path="/:id_boutique/products/dessert/new"         component={(p) => <ProductForm productCategory={dessert} router={p}/>}/>
        <Route exact path="/:id_boutique/products/dessert/:id/edit"    component={(p) => <ProductForm id={p.match.params.id} productCategory={dessert} id_boutique={this.props.id_boutique}/>} />
        <Route exact path="/:id_boutique/products/ingredient/new"      component={(p) => <IngredientForm  id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/ingredient/:id/edit" component={(p) => <IngredientForm id={p.match.params.id} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/ingredientsalade/new"      component={(p) => <IngredientSaladeForm  id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/ingredientsalade/:id/edit" component={(p) => <IngredientSaladeForm id={p.match.params.id} id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/products/new"                 component={(p) => <ProductForm id_boutique={this.props.id_boutique}/>}/>
        <Route path="/:id_boutique/products"                         render={() =>  <ProductsPage id_boutique={this.props.id_boutique} />}/>
      </Switch>
    );
  }
}

export default AdminProductsPage
