import * as React from 'react';
import { Route, Switch } from 'react-router';
import AdminReward from '../components/admin/AdminReward';
import AdminLottery from '../components/gift/AdminLottery';
import AdminSponsorshipGift from '../components/gift/AdminSponsorshipGift';
import GiftForm from '../components/gift/GiftForm';
import GiftList from '../components/gift/GiftList';

export interface IAdminRewardsPageProps {
  id_boutique: any
}

export interface IAdminRewardsPageState {
}

export default class AdminRewardsPage extends React.Component<IAdminRewardsPageProps, IAdminRewardsPageState> {
  constructor(props: IAdminRewardsPageProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <Switch>
        <Route exact path="/:id_boutique/rewards"                   component={(router) =>  <AdminReward id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/rewards/lottery"           component={() =>  <AdminLottery id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/rewards/sponsorship"       component={() =>  <AdminSponsorshipGift id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/rewards/gifts"              component={() =>  <GiftList id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/rewards/gifts/new"          component={() =>  <GiftForm id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/rewards/gifts/:id/edit"     component={(p) => <GiftForm id={p.match.params.id} id_boutique={this.props.id_boutique}/>}/>
      </Switch>
    );
  }
}
