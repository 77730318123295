import * as React from 'react';
import Actuality from '../../models/Actuality';
import ImageBg from '../common/ImageBg';
import dayjs from 'dayjs';

export interface IActualityCardProps {
  actuality: Actuality
}

export interface IActualityCardState {
}

export default class ActualityCard extends React.Component<IActualityCardProps, IActualityCardState> {
  constructor(props: IActualityCardProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {actuality} = this.props;
    return (
      <div className="actuality-card">
        <div className="pr-3">
          <ImageBg url={actuality.imageUrl} height="100%" width="100%" />
        </div>
        <div className="col-between h-100">
          <h6 className="font-weight-bold">{actuality.title}</h6>
          <div className="description">{actuality.description}</div>
          <div>{dayjs(actuality.createdAt).format("D MMMM YYYY")}</div>
        </div>
      </div>
    );
  }
}
