import classNames from 'classnames';
import * as React from 'react';
import ImageModel from '../../models/ImageModel';
import EntityManager from '../../services/EntityManager';
import MediaFileButton from './MediaFileButton';
import Model from '../../models/Model';
import ImageBg from './ImageBg';

export interface IImageGalleryProps {
  model: Model
  unique?: boolean
  standalone?: boolean
}

export interface IImageGalleryState {
}

class ImageGallery extends React.Component<IImageGalleryProps, IImageGalleryState> {
  constructor(props: IImageGalleryProps) {
    super(props);

    this.state = {
    }
  }

  deleteImage = async (image: ImageModel) => {
    const { model, standalone } = this.props;
    let result = window.confirm("Supprimer cette image ?")
    if (result) {
      if (standalone) await EntityManager.delete(image);
      model["images"] = model["images"].filter(i => i !== image)
      this.setState({});
    }
  }

  defineMain = async (image: ImageModel) => {
    // const { model } = this.props;
    // model["images"].byType(this.props.imageType).forEach((img, index) => {
    //   img.main = img.id === image.id
    // })
    // await EntityManager.updateMany(ImageModel, {params: model["images"].models, only: ["main"]});
    // this.setState({})
  }

  onAddImage = async (file: File) => {
    const { model, standalone } = this.props;
    let image = ImageModel.of(model, {type: "standard", file})
    if (standalone) await EntityManager.create(image);
    model["images"].push(image)
    model["image"] = image;
    this.setState({})
  }

  public render() {
    const { model, unique } = this.props;

    return (
      <div className="gallery">
        { (!unique || !model["image"]) && <MediaFileButton onFileChange={ this.onAddImage } >
          <div className="gallery-add-btn">
            <div className="m-auto">
              <i className="fas fa-plus icon-btn"></i>
              <div>Ajouter une photo</div>
            </div>
          </div>
        </MediaFileButton>}
        {model["image"] && <ImageBg image={model["image"]} url={model["image"].url} className="gallery-image">
          <div className="gallery-image-action">
            <div onClick={() => this.defineMain(model["image"])} className={classNames({"crown": true, "active": model["image"].main})}>
              <i className="fas fa-crown"></i>
            </div>
            <div onClick={() => this.deleteImage(model["image"])} className="close-btn">
              <i className="fas fa-times"></i>
            </div>
          </div>
        </ImageBg>}
      </div>
    );
  }
}

export default ImageGallery

