import { groupBy } from 'lodash';
import Option from '../models/Option';
import EntityManager from './EntityManager';

class OptionService {

  public options: {[name: string]: Option[]};
  public optionsList: Option[];

  get(key: string, sub?: string) {
    let options = this.options[key];
    if (sub) return options.find(o => o.key === sub)?.options;
    return options;
  }

  getNoChilds(key: string) {
    return this.options[key]?.filter(o => !o.optionId);
  }

  getDefault(key: string) {
    return this.options[key].find(o => o.default);
  }

  async load() {
    let options = (await EntityManager.all<Option>(Option)).models;
    this.optionsList = options;
    this.options = groupBy<Option>(options, "optionTypeName");
  }

  find(id: number): Option {
    return this.optionsList.find(o => o.id === id);
  }

  getOption(optionType: string, key: string) {
    return this.options[optionType].find(ot => ot.key === key);
  }

  getOptionId(optionType: string, key: string) {
    return this.getOption(optionType, key)?.id;
  }

}

export default new OptionService();