import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import SmartInputText from '../input/input/SmartInputText';
import SmartInputTextarea from '../input/input/SmartInputTextarea';
import { RouteComponentProps } from 'react-router';
import ApiService from '../../services/ApiService';

export interface IActualityFormProps {
    id_boutique: any
}

export interface IActualityFormState {
    title: string,
    body: string
}

class NotificationForm extends React.Component<IActualityFormProps & RouteComponentProps, IActualityFormState> {

    constructor(props: any) {
        super(props);
    
        this.state = {
            title: "",
            body: ""
        }
      }

    async submit() {
        //let submitted: any = await this.props.submit();
        
        await ApiService.post("admin/custom_notif", this.state);
        this.props.history.push("/" + this.props.id_boutique+ "/news")
    }


    public render() {

        return (
            <div>
                <div className="row-flex py-3 pr-3">
                    <NavLink to={"/news"} exact className="btn btn-link">
                        <i className="fas fa-chevron-left"></i>
                    </NavLink>
                    <div className="form-title mr-auto">Envoyer une notification</div>
                    <button onClick={() => this.submit()} className="btn btn-primary">Envoyer la notification</button>
                </div>
                <div className="p-3">
                    <SmartInputText name="title" placeholder="Titre" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} containerClassName="mb-3" />
                    <SmartInputTextarea name="description" placeholder="Description" value={this.state.body} onChange={(e) => this.setState({body: e.target.value})} containerClassName="mb-3" />
                </div>
            </div>
        );
    }
}

export default withRouter(NotificationForm)