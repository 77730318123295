import * as React from 'react';
import InputIcon from '../../common/InputIcon';
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartInputNumberProps extends ISmartInputProps {
  step?: React.Key
  min?: React.Key
  max?: React.Key
  noBootstrapClass?: boolean
}
export interface ISmartInputNumberState {
}

export default function SmartInputNumber(props: ISmartInputNumberProps) {

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {}

  return (
    <SmartInput
      {...props}
      render={({containerClassName, updateState, errorMessage, label, inputProps, leftIcon, rightIcon}) => (
        <div className={containerClassName}>
          { (label) && <label htmlFor={ inputProps.id || inputProps.name }>{ label }</label> }
            <InputIcon leftIcon={leftIcon} rightIcon={rightIcon} >
              <input
                {...inputProps}
                className={inputProps.className + " form-control"}
                onKeyDown={ (e) => { if (e.key === "Enter" && props.onEnter) props.onEnter() } }
                onWheel={onWheel}
                type={"number"}
                onChange={ (e) => updateState(e.target.value ? parseFloat(e.target.value) : null, e) }
              />
            </InputIcon>
          {/* { error && <div className="error-message">{error}</div>} */}
        </div>
      )}
    />
  );
}