import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Product from './Product';
import ModelAttribute from './core/ModelAttribute';

export default class Extra extends Model {

  public composableId: number
  public composableType: string
  public productId: number
  public product: Product
  public quantity: number

  static modelUrl = "extras";
  public modelName = "extra";

  public attributes = new AttributesCollection({
    composableId: new Attribute("composableId"),
    composableType: new Attribute("composableType"),
    productId: new Attribute("productId"),
    product: new ModelAttribute("product", Product),
    quantity: new Attribute("quantity"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}