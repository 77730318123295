import OptionService from '../services/OptionService';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ManyToManyAttribute from './core/ManyToManyAttribute';
import { OptionAttribute } from './core/OptionAttribute';
import Model from './Model';
import Option from './Option';
import Product from './Product';
import RequiredValidator from './core/form/validators/RequiredValidator';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import ImageModel from './ImageModel';
import ModelAttribute from './core/ModelAttribute';

export default class Gift extends Model {

  public title: string
  public amount: number
  public percent: number
  public expireAt: Date
  public giftTypeId: number
  public key: string
  public giftType: Option
  public products: Product[]
  public image: ImageModel;

  static modelUrl = "gifts";
  public modelName = "gift";

  public attributes = new AttributesCollection({
    title: new Attribute("title", {validator: RequiredValidator}),
    amount: new Attribute("amount"),
    percent: new Attribute("percent"),
    key: new Attribute("key"),
    expireAt: new Attribute("expireAt"),
    giftType: new OptionAttribute("giftType", Option),
    products: new ManyToManyAttribute("products", Product, {key: "productIds"}),
    image: new ModelAttribute("image", ImageModel, {submittable: false}),
    images: new ArrayModelAttribute("images", ImageModel)
  })

  isPercent = () => this.giftTypeId === OptionService.getOptionId("gift_type", "gift_type_percent")
  isAmount = () => this.giftTypeId === OptionService.getOptionId("gift_type", "gift_type_amount")
  isProductFree = () => this.giftTypeId === OptionService.getOptionId("gift_type", "gift_type_product_free")
  isBigDeal = () => this.giftTypeId === OptionService.getOptionId("gift_type", "gift_type_special")

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}