import * as React from 'react';
import { Route, Switch } from 'react-router';
import OrderShow from '../components/admin/orders/OrderShow';
import OrdersList from '../components/admin/orders/OrdersList';

export interface IAdminOrdersPageProps {
  id_boutique: any
}

export interface IAdminOrdersPageState {
}

export default class AdminOrdersPage extends React.Component<IAdminOrdersPageProps, IAdminOrdersPageState> {

  public render() {
    return (
        <Switch>
          <Route exact path="/:id_boutique/orders"              component={(router) => <OrdersList id_boutique={this.props.id_boutique} defaultFilter={{"shop_id": router.match.params.id_boutique}}/>} />
          <Route exact path="/:id_boutique/orders/:id"          component={(p) => <OrderShow id={p.match.params.id} />}/>
        </Switch>
    );
  }
}

