import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';

export default class Lottery extends Model {

  public message: number
  public giftId: number
  public probability: number

  static modelUrl = "lotteries";
  public modelName = "lottery";

  public attributes = new AttributesCollection({
    message: new Attribute("message"),
    giftId: new Attribute("giftId"),
    probability: new Attribute("probability"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}