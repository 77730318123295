import ModelAttribute from "./ModelAttribute";
import Model from "../Model";

export default class ArrayModelAttribute extends ModelAttribute {

  public toApiData(parentModel: Model, stringify = false) {
    let value;
    if (!parentModel[this.name]) return null;
    value = parentModel[this.name].map((a) => a.attributes.toMapApi(a));
    if (stringify) value = JSON.stringify(value);
    return value;
  }

  public createAttribute(parentModel: Model, json) {
    return this.mapArrayModel(this.className, json[this.name]);
  }

  public mapArrayModel(modelClass, jsonArray: any[], parentModel?: Model) {
    if (parentModel) return jsonArray?.map((e) => new modelClass(e, parentModel)) || [];
    return jsonArray?.map((e) => new modelClass(e)) || [];
  }

  checkForSubmit() {
    let errors = 0;
    this.value.forEach(m => {if (!m.checkForSubmit()) errors ++ })
    return errors === 0;
  }

  hasFileAttribute() {
    let has = 0;
    this.value.forEach(m => {if (m.hasFileAttribute()) has ++ })
    return has > 0;
  }

}