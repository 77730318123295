import * as React from 'react';
import { NavLink } from 'react-router-dom';
import BaseProduct from '../../../models/BaseProduct';
import Option from '../../../models/Option';
import Product from '../../../models/Product';
import ImageGallery from '../../common/ImageGallery';
import SmartInputNumber from '../../input/input/SmartInputNumber';
import SmartInputText from '../../input/input/SmartInputText';
import SmartInputTextarea from '../../input/input/SmartInputTextarea';
import ProductFormLogic from './ProductFormLogic';

export interface IProductFormProps {
  id?: string
  productCategory: Option
}

export interface IProductFormState {
}

export default class ProductForm extends React.Component<IProductFormProps & any, IProductFormState> {

  public render() {
    const { productCategory, id } = this.props
    let newModel: BaseProduct;
    if (!id) {
      newModel = new BaseProduct({products: [new Product({})]});
      newModel.setOptionAttributesToDefault();
      newModel.productCategory = productCategory
    }
    return (
      <ProductFormLogic
        id={id}
        newModel={newModel}
        render={(baseProduct, submit, footer) => (
          <div>
            <div className="row-flex py-3 pr-3">
              <NavLink to={"/" + this.props.id_boutique + "/products/" + productCategory.key} exact className="btn btn-link">
                <i className="fas fa-chevron-left"></i>
              </NavLink>
              <div className="form-title mr-auto">{!this.props.id ? "Nouveau produit" : "Modifier un produit"}</div>
              <button onClick={submit} className="btn btn-primary">Enregistrer</button>
            </div>
            <div className="p-3">
              <SmartInputText model={baseProduct} name={"name"} placeholder="Nom du produit" containerClassName="mb-3"/>
              <SmartInputTextarea model={baseProduct} name={"description"} placeholder="Description" rows={5} containerClassName="mb-3"/>
              <SmartInputText model={baseProduct.products[0]} name={"reference"} placeholder="Référence" containerClassName="mb-3"/>
              <div className="form-title mb-3">Vente</div>
              <div className="mb-4 d-block d-sm-flex">
                <div className="mr-2 w-100">
                  <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[0]} name={"price"} placeholder="Prix de base" step="0.01" className="mb-3"/>
                </div>
                <SmartInputNumber leftIcon={<i className="fas fa-euro"/>} model={baseProduct.products[0]} name={"modifiedPrice"} placeholder="Prix modifié" step="0.01" className="mb-3"/>
              </div>
              <div className="mb-3 row-flex">
                <div className="form-title">Photos de votre produit</div>
              </div>
              <ImageGallery standalone={!!baseProduct.id} unique model={baseProduct} />
              {footer()}
            </div>
          </div>
        )}
      />
    );
  }
}
