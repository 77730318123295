import * as React from 'react';
import { NavLink } from 'react-router-dom';
import SmartSwitch from '../components/input/input/SmartSwitch';
import Option from '../models/Option';
import ApiService from '../services/ApiService';
import OptionService from '../services/OptionService';

export interface IAdminSettingsPageProps {
}

export interface IAdminSettingsPageState {
  deliveryModes: Option[]
  golden: boolean
  appActive: boolean
}

export default class AdminSettingsPage extends React.Component<IAdminSettingsPageProps, IAdminSettingsPageState> {
  constructor(props: IAdminSettingsPageProps) {
    super(props);

    this.state = {
      deliveryModes: OptionService.get("delivery_mode"),
      golden: null,
      appActive: null,
    }
  }

  async changeWheel(e) {
    const golden = e.currentTarget.checked;
    await ApiService.post("wheel", {golden});
    this.setState({golden});
  }

  async changeAppActive(e) {
    const appActive = e.currentTarget.checked;
    await ApiService.post("app_active", {appActive});
    this.setState({appActive});
  }

  componentDidMount() {
    this.getAppConfig()
  }

  async getAppConfig() {
    let {golden, active} = (await ApiService.get("config")).data;
    this.setState({golden: golden === "true", appActive: active === "true"});
  }

  public render() {
    const {deliveryModes, golden, appActive} = this.state;
    if (golden == null) return <></>;
    return (
      <div className="p-3">
        <div className="row-between mb-3">
          <h4 className="mb-0 font-weight-bold mr-auto">Paramètres</h4>
          <div className="">
            <NavLink className="btn btn-link" to="/logout">Se déconnecter</NavLink>
          </div>
        </div>
        <div>
          <div className="text-secondary mb-2">Mode de livraison actif</div>
          <div>
            {
              deliveryModes.map((mode) => {
                return <div key={mode.id} className="row-between">
                  <div>{mode.label}</div>
                  <div><SmartSwitch model={mode} name="active" autoSave/></div>
                </div>
              })
            }
          </div>
        </div>
        <div>
          <div className="text-secondary mb-2 mt-4">Eurywheel</div>
          <div className="row-between">
            <span>Activer la golden wheel</span>
            <SmartSwitch value={golden} onChange={(e) => this.changeWheel(e)}/>
          </div>
          <div className="text-secondary mb-2 mt-4">Application</div>
          <div className="row-between">
            <span>Prise de commandes actif</span>
            <SmartSwitch value={appActive} onChange={(e) => this.changeAppActive(e)}/>
          </div>
        </div>
      </div>
    );
  }
}
