import BaseModal from "../components/modal/BaseModal";
import BaseToaster from "../components/toaster/BaseToaster";
// import AlertDialog from '../components/modal/AlertDialog';
// import { IAlertDialogProps } from '../components/modal/AlertDialog';
import DialogBase from '../components/modal/DialogBase';
import AppSidebar from "../components/layout/AppSidebar";
import AlertDialog, { IAlertDialogProps } from "../components/modal/AlertDialog";
import React from "react";

export default class DOMService {

  static modalRef: BaseModal;
  static toasterRef: BaseToaster;
  static dialogRef: DialogBase
  static sidebarRef: AppSidebar;
  static notifierRef;

  static modal(modal: JSX.Element) {
    DOMService.modalRef.setModal(modal);
    return this;
  }

  static alert(props: IAlertDialogProps) {
    props.onCancel = () => {
      DOMService.close();
    }
    DOMService.modalRef.setModal(<AlertDialog {...props} />)
  }

  static openSidebar(page, props?) {
    DOMService.sidebarRef.show(page, props)
  }

  static pop(modal: JSX.Element, props: any = {}) {
    return this;
  }

  static show() {
    DOMService.modalRef.setState({show: true})
  }

  static close() {
    DOMService.modalRef.setState({show: false, modal: null})
  }

  static setToaster(text) {
    DOMService.toasterRef.setToast(text);
    return this;
  }

  static dialog(model, promise: Promise<any>) {
    DOMService.dialogRef.show(model, promise);
    return this;
  }

  // static closeToast() {
  //   toaster.setState({show: false})
  // }

  // static toast(toast: JSX.Element) {
  //   toaster.setToast(toast);
  //   return this;
  // }

}
