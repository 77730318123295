import Attribute from "./Attribute";
import Model from "../Model";

export default class ModelAttribute extends Attribute {

  public className;

  constructor(name: string, className: any, options?) {
    super(name, options);
    this.className = className;
  }

  public toApiData(parentModel: Model, stringify = false) {
    let value;
    if (!parentModel[this.name]) return null;
    value = parentModel[this.name].attributes.toMapApi(parentModel);
    if (stringify) value = JSON.stringify(value);
    return value;
  }

  public createAttribute(parentModel: Model, json) {
    return json[this.name] && new this.className(json[this.name])
  }

  checkForSubmit() {
    if ((this.validator && !this.validator.check(this.getValue())) || (this.value && !this.value.checkForSubmit())) {
      this.change();
      return false
    };
    return true;
  }

  hasFileAttribute() {
    return this.value?.attributes?.hasFileAttribute();
  }

}