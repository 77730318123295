import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Notice from '../../models/Notice';
import recordManager from '../logic/RecordManager';
import TextIcon from '../common/TextIcon';

export interface INoticeListProps {
  router: any,
  id_boutique: any
}

export interface INoticeListState {
}

class NoticeList extends React.Component<any, INoticeListState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {models} = this.props;
    
    return <div>
      <div className="row-between p-3">
        <h5 className="mb-0 font-weight-bold">Avis des clients</h5>
        <div className="row-flex">
          <NavLink to="" exact className="btn btn-primary" onClick={this.props.router.history.goBack}>Retour</NavLink>
        </div>
      </div>
      <div className="actualities-container p-3">
        {
          models && models.map(notice => (
            <div className="notice-card">
              <div className="row-between mb-3">
                <TextIcon leftIcon="fas fa-user">{notice.user.firstname}</TextIcon>
                <TextIcon rightIcon="fas fa-star text-warning" >{notice.notation}</TextIcon>
              </div>
              <div>{notice.comment}</div>
              {notice.order.deliveryMan && <div className="font-weight-bold">Livreur : {notice.order.deliveryMan}</div>}
            </div>
          ))
        }
      </div>
    </div>
  }
}

export default recordManager(NoticeList, {modelClass: Notice, loadOnReady: true})