import Model from './Model';
import Product from './Product';
import ModelAttribute from './core/ModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';

export default class ProductComposition extends Model {

  public productId: number;
  public product: Product;
  public quantity: number;

  static modelUrl = "product_compositions";
  public modelName = "productComposition";

  public attributes = new AttributesCollection({
    productId: new Attribute("productId"),
    quantity: new Attribute("quantity"),
    product: new ModelAttribute("product", Product),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}