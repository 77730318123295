import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ModelAttribute from './core/ModelAttribute';
import Model from './Model';
import Order from './Order';
import User from './User';

export default class Notice extends Model {

  public user: User
  public comment: string
  public notation: number
  public order: Order

  static modelUrl = "notices";
  public modelName = "notice";

  public attributes = new AttributesCollection({
    user: new ModelAttribute("user", User),
    comment: new Attribute("comment"),
    notation: new Attribute("notation"),
    order: new ModelAttribute("order", Order),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}