import * as React from 'react';

export interface IIconCardProps {
  label, text, linkHref?, linkLabel?, color, icon
}

export interface IIconCardState {
}

export default class IconCard extends React.Component<IIconCardProps, IIconCardState> {
  constructor(props: IIconCardProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {label, text, /*linkHref, linkLabel,*/ color, icon} = this.props
    return (
      <div className="card">
        <div className="row-flex">
          <div className={"round-icon " + color}>
            <i className={"fas fa-" + icon}></i>
          </div>
          <div>
            <div className="card-title mb-0">{label}</div>
            <div className="chart-value">
              {text}
          </div>
          </div>
        </div>
        {/* { (linkHref && linkLabel) && <div className="p⁻3">
          <NavLink to={linkHref}>{linkLabel}</NavLink>
        </div>} */}
      </div>
    );
  }
}
