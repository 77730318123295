import classNames from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { NavLink, Switch } from 'react-router-dom';
import { ICrudBlocState } from '../../../bloc/CrudBloc';
import Product from '../../../models/Product';
import OptionService from '../../../services/OptionService';
import DessertList from './DessertList';
import DrinkList from './DrinkList';
import IngredientList from './IngredientList';
import PeckishList from './PeckishList';
import PizzaList from './PizzaList';
import IngredientSaladeList from './IngredientSaladeList';

export interface IProductsPageProps {
  id_boutique: any
}

export interface IProductsPageState {
  stockMode: boolean
}

class ProductsPage extends React.Component<ICrudBlocState<Product> & RouteComponentProps & any, IProductsPageState> {
  constructor(props: ICrudBlocState<Product> & RouteComponentProps & any) {
    super(props);

    this.state = {
      stockMode: false
    }
  }

  public render() {
    const {stockMode} = this.state;
    return (
      <div className="p-3">
        <div className="action-bar row-between mb-3">
          <h4 className="mb-0 font-weight-bold">
            <Route exact path="/:id_boutique/products/pizza"      render={() => <>Pizzas</>}/>
            <Route exact path="/:id_boutique/products/peckish"    render={() =>  <>P'tit creux</>}/>
            <Route exact path="/:id_boutique/products/drink"      render={() =>  <>Boissons</>}/>
            <Route exact path="/:id_boutique/products/dessert"    render={() =>  <>Desserts</>}/>
            <Route exact path="/:id_boutique/products/ingredient" render={() =>  <>Ingrédients</>}/>
            <Route exact path="/:id_boutique/products/ingredientsalade" render={() =>  <>Ingrédients Salade</>}/>
          </h4>
          <div className="row-end">
            <div className={classNames({"btn rounded-pill mr-3": true, "btn-light": !stockMode, "btn-primary": stockMode})} onClick={() => this.setState({stockMode: !stockMode})}>
              <span className="d-none d-sm-block">Gérer disponibilité</span>
              <i className="fas fa-cog d-block d-sm-none"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
                <div onClick={onClick} className="btn btn-light rounded-pill" ref={ref}>
                  <i  className="fas fa-ellipsis-h my-auto"></i>
                </div>
              ))}/>
              <Dropdown.Menu align="right" className="fade" style={{marginTop: "1rem"}} >
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/pizza"} >
                  <div>Pizzas</div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/peckish"} >
                  <div>P'tit creux</div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/drink"} >
                  <div>Boissons</div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/dessert"} >
                  <div>Desserts</div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/ingredient"} >
                  <div>Ingrédients</div>
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/"+ this.props.id_boutique +"/products/ingredientsalade"} >
                  <div>Ingrédients salade</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Switch>
          <Route exact path="/:id_boutique/products" render={() =>  <Redirect to={"/" + this.props.id_boutique +"/products/pizza"} />}/>
          <Route exact path="/:id_boutique/products/pizza" render={() =>   <PizzaList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "pizza"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique} />}/>
          <Route exact path="/:id_boutique/products/peckish" render={() => <PeckishList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "peckish"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique}/>}/>
          <Route exact path="/:id_boutique/products/drink"   render={() => <DrinkList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "drink"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique}/>}/>
          <Route exact path="/:id_boutique/products/dessert" render={() => <DessertList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "dessert"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique}/>}/>
          <Route exact path="/:id_boutique/products/ingredient" render={() => <IngredientList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "ingredient"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique}/>}/>
          <Route exact path="/:id_boutique/products/ingredientsalade" render={() => <IngredientSaladeList stockMode={stockMode} defaultFilter={{"productCategoryId": OptionService.getOptionId("product_category", "ingredient salade"), "shopId": this.props.id_boutique}} id_boutique={this.props.id_boutique}/>}/>
        </Switch>
      </div>
    );
  }
}

export default ProductsPage
