import classNames from 'classnames';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import Order from '../../../models/Order';
import NotificationService from '../../../services/NotificationService';
import OptionService from '../../../services/OptionService';
import SearchInput from '../../input/input/SearchInput';
import recordManager, { IInjectedRecordManagerProps } from '../../logic/RecordManager';
import OrderCard from './OrderCard';

export interface IOrdersListProps extends IInjectedRecordManagerProps<Order> {
}

export interface IOrdersListState {
  activeScope: any
  printerStatus: boolean
}

class OrdersList extends React.Component<IOrdersListProps & RouteComponentProps, IOrdersListState> {

  public scopes = [
    {name: "En attente", status: OptionService.getOption("order_status", "order_status_paid")},
    {name: "En préparation", status: OptionService.getOption("order_status", "order_status_preparing")},
    {name: "En livraison", status: OptionService.getOption("order_status", "order_status_delivering")},
  ]
  public interval;

  constructor(props) {
    super(props);

    this.state = {
      activeScope: null,
      printerStatus: false
    }
  }

  componentDidMount() {
    this.fallbackProcess();
    this.setupPrinter();
  }

  setupPrinter(){

    const printerIP = "192.168.1.250";  // Remplacez par l'adresse IP de votre imprimante
    const printerPort = 8008;  // Port par défaut pour les imprimantes Epson ePOS

    const epos = new window.epson.ePOSDevice();

    epos.connect(printerIP, printerPort, (result) => {
      if (result === 'OK') {
        this.setState({"printerStatus": true})
      } else {
        this.setState({"printerStatus": false})
      }
      console.log(result)
    });

    return () => {
      epos.disconnect();
    };
  }

  fallbackProcess() {
    this.interval = setInterval(this.checkNewOrder, 30000);
  }

  checkNewOrder = async () => {
    let count = this.props.models.length;
    await this.props.manager.loadModels();
    setTimeout(() => {
      if (count !== this.props.models.length) {
        let audio = new Audio('https://apieurythmeal.urioz.com/sounds/notif_sound.mp3');
        audio.play();
      }
    }, 500)
  }

  componentWillUnmount() {
  }

  onScope(scope) {
    if (this.state.activeScope === scope) {
      this.setState({activeScope: null}, () => this.props.manager.onFilter("status_id", null))
    } else {
      this.setState({activeScope: scope}, () => this.props.manager.onFilter("status_id", scope.status.id))
    }
  }

  onReset() {
    this.setState({activeScope: null}, () => this.props.manager.onFilter("status_id", null))
  }

  openOrder(e) {
  }

  public render() {
    const { models, manager, search, input } = this.props;
    return (
      <>
        
        <div className="action-bar d-block d-sm-flex row-flex px-3 pt-3">
          <h4 className="font-weight-bold mr-3 text-center d-none d-md-flex">Commandes</h4>
          <div className="row-flex w-100">
            <SearchInput className="w-100 mr-3" onClear={() => manager.clearSearch()} innerRef={input} onChanged={(val) => manager.handleSearch(val)} onSearch={() => manager.onSearch()} value={search} placeholder="Recherche par numéro" />
            <Dropdown>
              <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
                <div className="btn btn-light rounded-pill d-block d-md-none" ref={ref} onClick={onClick}>
                  <i className="fas fa-filter "></i>
                </div>
              ))}/>
              <Dropdown.Menu align="right" className="fade" style={{marginTop: "1rem"}} >
                {this.state.activeScope && <Dropdown.Item onClick={() => this.onReset()} >
                  <div>Reset</div>
                </Dropdown.Item>
                }
                {this.scopes.map((scope) => (
                <Dropdown.Item onClick={() => this.onScope(scope)} active={this.state.activeScope === scope} >
                    {scope.name}
                </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            
          </div>
          <div className="order-scopes row-flex ml-auto d-none d-md-flex">
            {this.state.activeScope && <div onClick={() => this.onReset()} className="order-scope-button reset-scope">
              Reset
            </div>}
            {this.scopes.map((scope) => (
              <div key={scope.name} className={classNames({"order-scope-button text-nowrap ml-3": true, "active": this.state.activeScope === scope})} onClick={() => this.onScope(scope)}>
                {scope.name}
              </div>
            ))}
          </div>
          <br></br>
          {this.state.printerStatus ? <div className='order-scope-button text-nowrap ml-1 bg-success text-light'>Imprimante disponible</div> : <div className='order-scope-button text-nowrap ml-1 bg-danger text-light'>Imprimante indisponible</div>}
        </div>
        
        <div className="orders-container">
          {
            models && models.map(order => (
              <div className="pr-3" key={order.getElementId()}>
                <OrderCard printerStatus={this.state.printerStatus} onCardClick={() => {}} onStatusChange={() => manager.loadModels()} order={order} />
              </div>
          ))}
        </div>
      </>
    );
  }
}

export default recordManager(OrdersList, {modelClass: Order, loadOnReady: true, paginate: true})