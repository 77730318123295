import * as React from 'react';
import classNames from 'classnames';
import ImageModel from '../../models/ImageModel';
import imagePlaceholder from '../../assets/img/image-placeholder.jpg';

export interface IImageBgProps {
  url?: string
  size?: string
  image?: ImageModel
  width?: string
  height?: string
  rounded?: boolean
  circle?: boolean
  fallbackUrl?: string
  className?: string
}

export interface IImageBgState {
  url: string
}

export default class ImageBg extends React.Component<IImageBgProps, IImageBgState> {

  defaultFallbackUrl = imagePlaceholder;

  constructor(props: IImageBgProps) {
    super(props);

    this.state = {
      url: props.url
    }
  }

  componentDidMount() {
    if (!this.props.url && this.props.image) this.loadPreview();
  }

  loadPreview = () => {
    let reader: FileReader = new FileReader();
    reader.onloadend = () => this.setState({url: reader.result as string});
    reader.readAsDataURL(this.props.image.file)
  }

  getBackgroundImage() {
    const { url } = this.state;
    if (url) return "url("+url+")";
    else     return "url("+this.getFallbackUrl()+")"
  }

  getFallbackUrl() {
    return this.props.fallbackUrl || this.defaultFallbackUrl;
  }

  public render() {
    const { size, rounded, width, height, circle, className, children }= this.props;

    return (
      <div style={
        {
          width: size || width,
          minWidth: size || width,
          height: size || height,
          backgroundImage: this.getBackgroundImage()
        }
      }
        className={classNames({"bg-image": true, "rounded-circle": circle, "rounded-lg": rounded, [className]: !!className})}
      >{children}</div>
    );
  }
}
