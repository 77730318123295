import * as React from 'react';
import { Toast } from 'react-bootstrap';

export interface ICustomToastProps {
  text: string
  onClose: () => void
}

export interface ICustomToastState {
}

export default class CustomToast extends React.Component<ICustomToastProps, ICustomToastState> {
  constructor(props: ICustomToastProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {text, onClose} = this.props;
    return (
      <Toast onClose={onClose} animation delay={6000} className="toast-success">
        <Toast.Header closeButton={false} >
          <strong className="mr-auto">Merci Bibi</strong>
          <div onClick={onClose} className="close-btn">
            <i className="far fa-times text-secondary ml-auto"></i>
          </div>
        </Toast.Header>
        <Toast.Body>
          <i className="far fa-check-circle mr-2"></i>
          {text}
        </Toast.Body>
      </Toast>
    );
  }
}
