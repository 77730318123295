import * as React from 'react';
import { Switch, Route } from 'react-router';
import ActualityForm from '../components/actuality/ActualityForm';
import ActualityList from '../components/actuality/ActualityList';
import NotificationForm from '../components/actuality/NotificationForm';

export interface IAdminNewsPageProps {
  id_boutique: any
}

export interface IAdminNewsPageState {
}

export default class AdminNewsPage extends React.Component<any, IAdminNewsPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <Switch>
        <Route exact path="/:id_boutique/news"                   component={() =>  <ActualityList id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/news/new"               component={() => <ActualityForm id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/news/notif"               component={() => <NotificationForm id_boutique={this.props.id_boutique}/>}/>
        <Route exact path="/:id_boutique/news/:id/edit"          component={(p) => <ActualityForm id_boutique={this.props.id_boutique} id={p.match.params.id} />}/>
      </Switch>
    );
  }
}
