import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IAdminRewardProps {
  id_boutique: any
}

export interface IAdminRewardState {
}

export default class AdminReward extends React.Component<IAdminRewardProps, IAdminRewardState> {
  constructor(props: IAdminRewardProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    console.log(this.props)
    return (
      <div className="container h-100">
        <div className="gift-tabs row h-100">
          <div className="col-md-4 m-auto">
            <NavLink className="gift-tabs-item" exact to={"/" + this.props.id_boutique +"/rewards/gifts"}>
              <div className="m-auto flex-column d-flex align-items-center">
                <i className="fas fa-gift mr-3"></i>
                <div>Cadeaux</div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 m-auto">
            <NavLink className="gift-tabs-item" exact to={"/" + this.props.id_boutique +"/rewards/lottery"}>
              <div className="m-auto flex-column d-flex align-items-center">
                <i className="fas fa-club mr-3"></i>
                <div>Lotterie</div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 m-auto">
            <NavLink className="gift-tabs-item" exact to={"/" + this.props.id_boutique +"/rewards/sponsorship"}>
              <div className="m-auto flex-column d-flex align-items-center">
                <i className="fas fa-users mr-3"></i>
                <div>Parrainage</div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
