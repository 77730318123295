import * as React from 'react';
import Gift from '../../models/Gift';
import SponsorshipReward from '../../models/SponsorshipReward';
import EntityManager from '../../services/EntityManager';
import TextIcon from '../common/TextIcon';
import recordManager from '../logic/RecordManager';
import GiftModelSelect from '../modelSelect/GiftModelSelect';
import DOMService from '../../services/DOMService';
import { NavLink } from 'react-router-dom';
import SmartInputNumber from '../input/input/SmartInputNumber';

export interface IAdminSponsorshipGiftProps {
  id_boutique
}

export interface IAdminSponsorshipGiftState {
}

class AdminSponsorshipGift extends React.Component<any, IAdminSponsorshipGiftState> {

  public gifts: Gift[]

  async componentDidMount() {
    this.gifts = (await EntityManager.all<Gift>(Gift)).models;
    this.setState({});
  }

  async addRow() {
    let newRow = (await EntityManager.create<SponsorshipReward>(new SponsorshipReward({sort: this.props.models?.length - 1}))).model;
    this.props.manager.setState({models: [...this.props.models, newRow]})
  }

  async sort(reward: SponsorshipReward, newIndex) {
    await EntityManager.update(reward, {only: ["sort"]});
  }

  async removeRow(reward: SponsorshipReward) {
    DOMService.alert({
      title: "Suppression d'une étape",
      message: "Etes vous sûr de vouloir supprimer cette étape de parrainage ?",
      onConfirm: async () => {
        await EntityManager.delete<SponsorshipReward>(reward);
        this.props.manager.setState({models: this.props.models.filter(sr => sr.id !== reward.id)});
        DOMService.close();
      }
    });
  }

  public render() {
    const {models} = this.props
    if (!this.gifts) return <></>
    return (
      <div className="">
        <div className="row-flex pt-3 pr-3">
          <NavLink to={"/" + this.props.id_boutique +"/rewards"} exact className="btn btn-link">
            <i className="fas fa-chevron-left"></i>
          </NavLink>
          <div className="form-title mr-auto">Etape de parainnage</div>
        </div>
        <div className="p-3">
          {models && models.map((reward, index) => (
            <div key={reward.id} className="row-flex py-2">
              <div className="font-weight-bold" style={{flex: "0 0 30px"}}>{index + 1}</div>
              <GiftModelSelect autoSave model={reward} name="giftId" models={this.gifts} />
              <SmartInputNumber autoSave model={reward} name="tokens" containerClassName="ml-3 w-25" placeholder="Jetons gagnés" />
              <div onClick={() => this.removeRow(reward)} style={{flex: "0 0 60px", fontSize: "1.25rem"}} className="text-center text-danger">
                <i className="fas fa-times"></i>
              </div>
            </div>
          ))}
          {models?.length > 0 && <div className="row-flex py-2">
            <div onClick={() => this.addRow()} className="btn btn-primary btn-block">
            <TextIcon leftIcon="fas fa-plus">Ajouter</TextIcon>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default recordManager(AdminSponsorshipGift, {modelClass: SponsorshipReward, loadOnReady: true})
