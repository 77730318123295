import * as React from 'react';
import TextIcon from '../common/TextIcon';

export interface IAlertDialogProps {
  title: string
  message: string
  buttonText?: string
  onConfirm: () => void
  onCancel?: () => void
}

export interface IAlertDialogState {
}

export default class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState> {
  constructor(props: IAlertDialogProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { title, message, onConfirm, onCancel, buttonText } = this.props;
    return (
      <div className="alert-dialog">
        <div className="alert-dialog-header">
          <TextIcon iconClassName="text-danger" leftIcon={"fas fa-exclamation-circle"}>{title}</TextIcon>
        </div>
        <div className="alert-dialog-body">
          {message}
        </div>
        <div className="alert-dialog-footer">
          <button className="btn btn-light mr-3" onClick={() => onCancel()}>Annuler</button>
          <button className="btn btn-danger" onClick={() => onConfirm()}>{buttonText || "Confirmer"}</button>
        </div>
      </div>
    );
  }
}
