import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Admin from './Admin';
import './assets/scss/app.scss';
import PrivateRoute from './components/logic/PrivateRoute';
import BaseModal from './components/modal/BaseModal';
import DialogBase from './components/modal/DialogBase';
import BaseToaster from './components/toaster/BaseToaster';
import LoginPage from './page/LoginPage';
import LogoutPage from './page/logoutPage';
import authService from './services/AuthService';
import LocaleService from './services/LocaleService';
import NotificationService from './services/NotificationService';
import OptionService from './services/OptionService';
import SessionService from './services/SessionService';

dayjs.locale('fr')
export interface IRootProps {
}

export interface IRootState {
  initialized: boolean
}

export default class Root extends React.Component<IRootProps, IRootState> {
  constructor(props: IRootProps) {
    super(props);

    this.state = {
      initialized: false
    }
  }

  componentDidMount() {
    this.init();
  }

  public async initSession() {
    if (SessionService.hasToken()) {
      await authService.me();
      if (NotificationService.supported) await SessionService.initDevice();
    }
  }

  init = async () => {
    await OptionService.load();
    await LocaleService.loadLocale();
    await this.initSession();
    this.setState({initialized: true})
  }

  public render() {
    if (!this.state.initialized) return <></>
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path={'/login'} component={() => <LoginPage/>} />
            <Route exact path="/logout"  component={() => <LogoutPage /> } />
            <PrivateRoute path={'/'}     component={() => <Admin/>} />
          </Switch>
          <BaseModal/>
          <BaseToaster/>
          <DialogBase/>
        </BrowserRouter>
      </>
    );
  }
}

